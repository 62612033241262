@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';

.chartWrapper {
  max-height: 400px;
}

.chartTitle {
  @include shareMainContent_dashboard_item_title;
}

.chartTitleHighlight {
  color: $darkest_gray;
}
.chartContainer {
  width: 100%;
  height: 100%;
}
