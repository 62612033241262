@import './palette';
@import './text';
@import './button';
@import './responsive';

$labelWidth: 100px;

$form_save_cancel_btn_width: 13rem;

@mixin sharedForm_formWrapper {
  background-color: $white1;
  border-radius: 8px;
  padding: 1rem 1.6rem 1.6rem 1.6rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  @include Responsive(LG) {
    display: block;
  }
}

@mixin sharedForm_formField {
  border-bottom: 1px solid $gray_MBP_light;
  padding-bottom: 5.5px;
  margin-top: 1.5rem;
  display: flex;
  @include Responsive(MD) {
    display: block;
  }
}

@mixin sharedForm_formTitle {
  @include head2;
  color: $red1;
  margin-bottom: 0;
}

@mixin sharedForm_label {
  @include body1;
  font-weight: 600;
}

@mixin sharedForm_sup {
  color: $red1;
  font-size: 18px;
  font-weight: bold;
  top: 0;
}

@mixin sharedForm_inputField {
  font-family: $primary_font1;
  border: none;
  outline: none;
  width: 70%;
  color: $darkest_gray;
  &::placeholder {
    @include body1;
    letter-spacing: 2%;
  }
  @include Responsive(MD) {
    width: 100%;
    margin-top: 0.5rem;
  }
}

@mixin sharedForm_rightGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  @include Responsive(LG) {
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2rem;
  }
}
@mixin sharedForm_btnCancel {
  width: 10rem;
  height: 5rem;
  @include button;
  font-size: 18px;
  cursor: pointer;
  outline: none;
  @include secondary_btn;
  &:hover {
    border: $secondary_blue2;
  }
}
@mixin sharedForm_btnSave {
  width: 10rem;
  height: 5rem;
  @include button;
  font-size: 18px;
  cursor: pointer;
  outline: none;
  @include primary_disabled_btn;
}

@mixin sharedForm_activeSaveBtn {
  @include primary_btn;
}

@mixin sharedForm_editBtn {
  position: absolute;
  right: -5px;
  top: 0;
  @include primary_btn;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}

@mixin sharedForm_positioningInput {
  position: absolute;
  bottom: 0;
  right: 5rem;
  @include Responsive(MD) {
    position: relative;
    right: 0rem;
  }
}

@mixin sharedForm_pencilIcon {
  color: $white1;
  @include Responsive(MD) {
    margin-right: 0;
  }
}

@mixin sharedForm_dangerMessage {
  @include head1;
  color: $red_MPB;
  margin: 0;
}

/*UPCOMING AND DONE SESSION COMP*/
@mixin sessionTable {
  width: 100%;
  box-shadow: 2px 0px 9px rgba(40, 40, 40, 0.25);
  border-radius: 8px;
  border-collapse: collapse;
  border-radius: 8px 8px 0 0;

  thead {
    th {
      position: sticky;
      top: 0;
      @include head2;
      font-size: 18px;
      background-color: $red1;
      color: $white1;
      z-index: 99;
      padding: 1.5rem 16px;
      text-align: center;
      &:first-child {
        border-radius: 8px 0 0 0;
      }
      &:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }
  tbody {
    .noSessionTD {
      text-align: center;
    }
    tr {
      &:nth-child(even) {
        background-color: $gray_MPB_lightest;
      }
    }
    td {
      padding: 1.5rem 16px;
    }
  }
}
