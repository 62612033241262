@import '../../theme/palette';
@import '../../theme/text';
.searchWrapper {
  width: max-content;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 31px;
  background-color: $white1;
  padding-right: 0.2rem;
  border: 1px solid $gray_MBP_light;

  .searchBar {
    border: none;
    outline: none;
    padding: 7px 12rem 7px 1.4rem;
    border-radius: 31px 0 0 31px;
    &::placeholder {
      color: $darkest_gray;
      font-family: $primary_font1;
      font-weight: 700;
    }
  }
  .searchIcon {
    color: $gray_MPB;
    cursor: pointer;
  }
}

.btnSubmit {
  border: none;
  background-color: transparent;
}
