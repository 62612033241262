@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/responsive';

:global(#app) {
  .sortIcon {
    cursor: pointer;
    position: absolute;
    right: 0;
  }
}

.tableWrapper {
  margin-top: 0.8rem;
  box-shadow: 2px 0px 9px rgba(40, 40, 40, 0.25);
  border-radius: 8px;
  position: relative;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px 8px 0 0;
    .fixedHeader {
      .headerTR {
        height: 3.5rem;
      }
    }
  }
}

.headerTH {
  position: relative;
  top: 0;
  @include head2;
  font-size: 14px;
  background-color: $red1;
  color: $white1;
  padding: 1.5rem 16px;
  text-align: center;
  &:first-child {
    border-radius: 8px 0 0 0;
  }
  &:last-child {
    border-radius: 0 8px 0 0;
  }
}
.headerTitleBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clientTH {
  width: 20%;
}

.coacheeTH {
  width: 20%;
}

.offerTH {
  width: 20%;
}

.latestChangeTH {
  width: 16%;
}

.orderTH {
  width: 7%;
}

.statusTH {
  width: 7%;
}

.tableListRowInterrupted {
  font-style: italic;
}

.tbodyTD {
  @include body1;
  font-weight: 400;
  padding: 12px 16px;
}

.statusItem {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $secondary_blue2;
  margin: 0 auto;
}

.colorInterrupted {
  background-color: $gray1;
}

.colorAnomaly {
  background-color: $red1;
}

.colorDelay {
  background-color: $other_yellow1;
}
