@import '../../theme/palette';
@import '../../theme/text';

.contactsBlock {
  li {
    list-style: none;
    padding: 0.6rem 1rem 0.6rem 1.5rem;
    border-bottom: 1px solid $gray_MBP_light;
    font-family: $primary_font1;
    font-weight: 700;
    cursor: pointer;
  }
}

.contactHasUnread {
  position: relative;
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: $darkest_gray;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 95%;
  }
}

.contactActive {
  background-color: $red1;
  color: $white1;
}
