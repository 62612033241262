@import '../../theme/palette';

:global(#app) {
  .previewSelfDiagnosis {
    color: $gray_MPB;
    margin-left: 10px;

    &:hover {
      color: $red1;
    }
  }
}
