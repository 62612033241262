@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';
@import '../../theme/responsive';

:global(#app) {
  .customedSegment {
    @include sharedMainContent_customedSegment;
    box-shadow: none;
    padding: 0.5rem 1em;
  }

  .h100 {
    height: 100%;
  }

  .firstRowOfRightCol {
    padding-bottom: 0;
    @include Responsive(LG) {
      margin-top: 1rem;
    }
    @include Responsive(MD) {
      margin-top: 1.5rem;
    }
  }

  .secondRowOfRightCol {
    padding-top: 0;
  }
}
.wrapper {
  padding: 1.5rem 0 4rem 2rem;
  background-color: $gray_MPB_lightest;
}

.contentBlock {
  padding: 0 1.2rem 0 0;
}

/*CHARTS*/

.chartTitle {
  @include StronglyEmphasize;
  font-size: 18px;
}

.leftBottomWrapper {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 8px;
    top: 0;
    width: 3px;
    height: 100%;
    background-color: $gray_MBP_light;

    @include Responsive(SM) {
      display: none;
    }
  }
}

.leftBottomBlock {
  padding-left: 20px;
  @include Responsive(SM) {
    padding-left: 0;
  }
}

.heightForBlock {
  height: 175px;
  @include Responsive(SM) {
    height: 190px;
  }
}

.topNeedBlock {
  min-height: 175px;
  color: $darkest_gray;
}

.blockMarginBottom {
  margin-bottom: 1rem;
}
