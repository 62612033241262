@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';

:global(#app) {
  .closeBtn {
    @include primary_btn;
    width: 10rem;
  }
  .closeIcon {
    position: absolute;
    top: 5px;
    right: 0px;
    color: $white1;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  opacity: 1;
  display: flex;
  justify-content: center;
}

.wrapper {
  text-align: center;
  width: 80%;
  max-width: 72rem;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: absolute;
  top: 5%;
  background-color: $white1;
  .title {
    color: $white1;
    @include head2;
    font-size: 1.7rem;
    background-color: $red1;
    padding: 1.1rem 0;
    border-radius: 8px 8px 0 0;
  }
  .contentWrapper {
    padding: 0 1.5rem;
    text-align: left;
    margin-top: 2rem;
    max-height: 85vh;
    overflow-y: auto;
  }
}

.buttonsWrapper {
  display: flex;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  justify-content: flex-end;
}
