@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/responsive';

.evaluateBlock {
  padding: 0 2.2rem;

  @include Responsive(SM) {
    padding: 0 1rem;
  }
}

.evaluationText {
  @include overline;
  font-size: 18px;
}
.evaluationNumber {
  @include StronglyEmphasize;
  font-size: 24px;
}

.evaluateComp {
  margin-top: 1rem;
}

.rangeInput {
  width: 100%;
  margin-top: 0.3rem;
}
.evaluateUnits {
  display: flex;
  justify-content: space-between;
  margin-top: 0.3rem;
  span {
    @include StronglyEmphasize;
    font-size: 18px;
    color: $gray_MPB;
  }
}
