@import '../../theme/palette';
@import '../../theme/text';

.timeBlock {
  padding: 2rem 8px 0 8px;
  p {
    text-align: center;
    &:nth-child(1) {
      @include head2;
      color: $red1;
      margin-bottom: 0;
    }
  }
}

.timerNumber {
  font-family: $secondary_font1;
  font-size: 42px;
  color: $darkest_gray;
  font-weight: 700;
}
