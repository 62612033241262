@import './palette';

#paginationId {
  .menu {
    min-height: 2rem;
    box-shadow: none;
  }
  .item {
    padding: 0.3rem 0;
    outline: none;
    justify-content: center;
    min-width: 2rem;
    border: 1px solid $gray1;
  }
  .active {
    padding: 0;
    background-color: $red1;
    color: $white1;
    font-weight: 700;
  }
}
