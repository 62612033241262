@import '../../theme/text';
@import '../../theme/responsive';

:global(#app) {
  .gridWrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .columnYear {
    padding-left: 0;
    @include Responsive(MD) {
      padding-left: 1rem;
    }
  }
  .lastColumn {
    padding-right: 0;
    @include Responsive(MD) {
      padding-right: 1rem;
    }
  }
  .yearlyRow {
    align-items: center;
  }
}

.yearlyRow {
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 3px;
    background: #e0e0e0;
    // z-index: -1;
    top: calc(50% - 1.5px);
    left: 0;
    position: absolute;
  }
  &:first-child {
    .columnResume {
      .numberResume {
        color: #2f80ed;
      }
    }
    .columnYear {
      .blockYear {
        max-width: 150px;
        min-height: 100px;
        span {
          color: #eb5757;
        }
      }
    }
  }
}

.columnYear {
  padding-right: 30px;
  .blockYear {
    text-align: center;
    background: #fff;
    border: 1px solid #bdbdbd;
    border-radius: 10px;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #848484;
      font-weight: bold;
      font-size: 36px;
      letter-spacing: -0.03em;
      font-family: $secondary_font1;
    }
  }
}

.columnResume {
  .blockResume {
    text-align: center;
    background: #fff;
    border: 1px solid #bdbdbd;
    border-radius: 10px;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
  .numberResume {
    color: #a7a9ac;
    font-weight: bold;
    font-size: 36px;
    letter-spacing: -0.05em;
    font-family: $secondary_font1;
    margin-right: 5px;
  }
  .textResume {
    font-size: 14px;
    font-family: $primary_font1;
  }
}

@media screen and (max-width: $MD) {
  .yearlyRow {
    &:before {
      width: 3px;
      height: calc(100% - 3rem);
      top: 1rem;
      left: calc(50% - 1.5px);
    }
  }
  .columnYear {
    .blockYear {
      max-width: 250px;
      margin: 0 auto 20px;
    }
  }
  .columnResume {
    .blockResume {
      max-width: 250px;
      margin: 0 auto 15px;
    }
  }
}

.pastYeargray_MPB {
  color: $gray_MPB !important;
}

.pastYearGreyMPB {
  color: $gray_MPB !important;
}

.pastYearGrey1 {
  color: #333333 !important;
}
