@import '../../theme/palette';
@import '../../theme/text';

.row {
  &:nth-child(even) {
    background-color: $gray_MPB_lightest;
  }
  &:nth-child(odd) {
    background-color: $white1;
  }
  td {
    color: $darkest_gray;

    &:not(:first-child) {
      padding: 12px 16px;
      // text-align: start;
      @include body1;
    }
    ul {
      padding-left: 5px;
      list-style: none;
      li {
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.selected {
  background-color: $secondary_blue3 !important;
  color: $white1;
}

.fNameCol {
  color: $darkest_gray;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  span {
    margin-left: 5px;
  }
}
