@import '../../../theme/palette';
@import '../../../theme//text';
@import '../../../theme/button';

:global(#app) {
  .submitButton {
    color: #ffffff;
    font-family: $primary_font5;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
    background-color: $darkest_gray;
    border-radius: 8px;
    width: 90%;
    margin: 0;
    margin-top: 1rem;
    padding: 16px 10px;
    text-transform: uppercase;
    &:hover {
      background-color: $secondary_blue2;
    }
  }
  .submitButtonWidth2 {
    margin-top: 2rem;
    text-transform: uppercase;
    width: 10rem;
    @include primary_btn;
  }
}
.wrapper {
  margin-top: 24%;
  margin-bottom: 25%;
  max-width: 328px;
}

.title {
  font-family: $primary_font1;
  font-weight: 700;
  font-size: 18px;
}

.description {
  @include body2;
}

/*Sucess form*/
.formStyle2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    @include h2Headline;
    font-weight: 300;
    margin-bottom: 0.5rem;
  }
  ul {
    li {
      @include h2Headline;
      font-size: 600;
      padding: 0.5rem 0;
    }
  }
}
