@import '../../../theme/palette';
@import '../../../theme/text';

.searchWrapper {
  width: max-content;
  height: 100%;
  background-color: $white1;
  padding-right: 1rem;
  border: 1px solid $gray_MBP_light;
  min-height: 1.7em;
  display: flex;
  align-items: center;
  border-radius: 31px;
}

.searchBar {
  width: 300px;
  border: none;
  outline: none;
  padding: 7px 0 7px 1.4rem;
  border-radius: 31px 0 0 31px;
  &::placeholder {
    color: $gray_MPB;
    font-family: $primary_font1;
  }
}
.searchIcon {
  color: $gray_MBP_light;
  cursor: pointer;
}

.btnSubmit {
  border: none;
  background-color: transparent;
}

.noBorder {
  box-shadow: none;
  border: none;
  border-radius: 0;
}

.freeWidth {
  width: auto;
}
