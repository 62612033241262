@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/responsive';

:global(#app) {
  .topRowWrapper {
    border-bottom: 1px solid $gray_MPB;
    min-height: 70px;
    background-color: $white1;
  }

  .noBg {
    background-color: transparent;
  }
  .p0 {
    padding: 0;
  }
  .m0 {
    margin: 0;
  }
  .icon {
    font-size: 1.1rem;
    height: 1.1rem;
    color: inherit;
  }

  .notifiedBell {
    color: $gray_MPB;
    font-size: 1.2rem;
  }

  .notifiedMail {
    color: $gray_MPB;
    font-size: 1.3rem;
  }

  .userIcon {
    font-size: 2.3rem;
    height: 1.3rem !important;
    cursor: pointer;
    transition: all 0.3s;
    color: $gray_MPB;
    @include Responsive(XL) {
      font-size: 2rem;
    }
    &:hover {
      color: $secondary_blue2;
    }
    @include Responsive(SM) {
      display: none;
    }
  }

  .currentTitleIcon {
    color: $gray_MPB;
    font-size: 1.6rem;
  }

  .logoColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1.5px solid $gray_MPB;
    padding-left: 5px;
    padding-right: 5px;
    @include Responsive(LG) {
      display: none;
    }
  }
  .topMidColumn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.2rem;
    border-right: 1.5px solid $gray_MPB;
  }
  .positionLeft {
    justify-content: flex-end;
  }

  .hideUserIcon {
    display: none;
  }
}

.wrapper {
  height: 100%;
  background-color: $gray_MPB_lightest;
  display: flex;
  flex-flow: column;
  opacity: 0.9;
}
.whiteBackground {
  background-color: $white1;
}

.logo {
  max-width: 13rem;
}

.notifiedIconBlock {
  position: relative;
  cursor: pointer;
  .notifiedNumber {
    @include overlineBold;
    color: $white1;
    background-color: $red1;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin: 0;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    top: -16px;
  }
  .notifiedNumberPosition1 {
    right: -6px;
  }
  .notifiedNumberPosition2 {
    right: -10px;
  }
}

.userMenuCollapseBlock {
  position: relative;
  cursor: pointer;
  color: $darkest_gray;
}

.currentTitle {
  @include head2;
  color: $darkest_gray;
  letter-spacing: 1px;
  padding-left: 5px;
  cursor: pointer;
}

.topRightBlock {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.userNameAndTitleBlock {
  display: flex;
  align-items: center;
}

.nameAndTitleText {
  display: flex;
  flex-direction: column;
}

.userName {
  @include StronglyEmphasize;
  text-align: left;
  cursor: pointer;
  color: $darkest_gray;
}
.userTitle {
  @include mediumCaption;
  text-align: left;
  color: $gray1;
}

.iconAndTitleBlock {
  display: flex;
  align-items: center;
}
