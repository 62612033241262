@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';

.wrapper {
  @include sharedMainContent_wrapper;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

.titleAndNavBlock {
  @include sharedMainContent_titleAndNavBlock;
  border-bottom: none;
}

.title {
  @include sharedMainContent_title;
}

.titleDesc {
  @include sharedMainContent_titleDesc;
}

/*PROGRAM BLOCK*/
.programsBlock {
  @include sharedMainContent_contentBlock;
  margin-top: 2rem;
}