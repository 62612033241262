@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';
@import '../../theme/responsive';

:global(#app) {
  .customSegment {
    padding-left: 0;
    padding-right: 0;
    border: 2px solid $gray_MPB;
    border-radius: 10px;
  }

  .redBorder {
    border-color: $red1;
  }

  .violetBorder {
    border-color: $violet;
  }

  .videoIcon {
    color: $gray_MBP_light;
    margin-right: 8px;
  }

  .joinBtn {
    @include primary_btn;
    // width: 100%;
    height: 3rem;
    margin-bottom: 1.2rem;

    @include Responsive(MD) {
      margin-bottom: 0;
    }
  }

  .disableBtn {
    @include primary_disabled_btn;
  }

  .activeBtn {
    @include primary_btn;
  }

  .cancelBtn {
    @include tertiary_btn;
    min-height: 3rem;
  }
  .editVideoLinkBlock {
    display: flex;
    cursor: pointer;
    align-items: center;
    &:hover {
      .videoIcon {
        color: $darkest_gray;
      }
      .text7 {
        color: $darkest_gray;
        text-decoration: underline;
      }
    }
  }

  .needSetVideoLink {
    .videoIcon,
    .text7 {
      color: $darkest_gray;
    }
    .videoIconDanger,
    .text7Danger {
      color: $red_MPB;
    }
  }
}

// TOP BLOCK
.topBlock {
  padding: 0 1rem 1rem 1rem;
  border-bottom: 2px solid $gray_MBP_light;
}

.topLeft {
  display: flex;
  justify-content: space-between;
  text-align: center;

  @include Responsive(MD) {
    margin-bottom: 10px;
  }
}

.topRight {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.text1 {
  font-family: $primary_font1;
  font-size: 18px;
  font-weight: 700;
  color: $gray2;
}

.text2 {
  font-family: $primary_font1;
  font-size: 18px;
  font-weight: 700;
  color: $gray_MPB;
}

.text3 {
  font-family: $primary_font1;
  font-size: 18px;
  font-weight: 600;
  color: $gray_MPB;
}

.text4 {
  font-family: $primary_font1;
  font-size: 18px;
  font-weight: 600;
  color: $darkest_gray;
}

.sessionText {
  margin-right: 2rem;
}

//BOTOM BLOCK
.bottomBlock {
  padding: 1rem 1rem 0 1rem;

  .bottomLeft {
    display: flex;
    flex-direction: column;

    .actionContainer {
      display: flex;
    }
  }
}

.bottomMid {
  display: flex;
}

.bottomRight {
  display: flex;
  flex-direction: column;

  @include Responsive(MD) {
    margin-top: 1.2rem;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  @include Responsive(SM) {
    flex-direction: column;
    align-items: unset;
    gap: 1rem;
  }
}

.text5 {
  font-weight: 600;
  margin-bottom: 0.9rem;
  font-family: $primary_font1;
  font-size: 18px;
}

.text6 {
  font-weight: 400;
  color: $gray_MPB;
  margin-bottom: 0.5rem;
  font-family: $primary_font1;
  font-size: 18px;
}

.text7 {
  font-family: $primary_font1;
  font-weight: 400;
  font-size: 16px;
}

.bold700 {
  font-weight: 700;
}

.invisible {
  opacity: 0;
  pointer-events: none;
}

.text1,
.text2,
.text3,
.text4 {
  @include Responsive(MD) {
    font-size: 16px;
  }
}
