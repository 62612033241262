@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';
@import '../../theme/responsive';

:global(#app) {
  .customeSegment {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border: 4px solid $white1;
    border-radius: 8px;
  }
  .selectedActive {
    border: 4px solid $red1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

/*LEFT BLOCK*/
.coachName {
  @include head2;
  color: $red_MPB;
  margin-bottom: 1rem;
}

.imgWrapper {
  display: flex;
  justify-content: center;
  @include Responsive(MD) {
    margin-bottom: 1rem;
  }
}

/*RIGHT BLOCK*/
.categoryText {
  @include subtitle;
}

.introWrapper {
  border: 1.5x solid #f0f0f0;
  p {
    @include body1;
    cursor: default;
  }
}

.listing {
  display: flex;
  flex-wrap: wrap;
  li {
    min-width: 9rem;
  }
}
