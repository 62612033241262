@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';
@import '../../theme/sharedForm';

.formWrapper {
  @include sharedForm_formWrapper;
  position: relative;
  display: flex;
  max-width: 1200px;
  padding: 1rem 1.6rem 3rem 1.6rem;
  margin-top: 2rem;
  form {
    width: 80%;
  }
}

.formField {
  @include sharedForm_formField;
  align-items: center;
}

.formRow {
  position: relative;
}

.widthFirstName {
  width: 42%;

  @include Responsive(SM) {
    width: 100%;
  }
}

.positioningInput {
  position: absolute;
  bottom: 0;
  right: 0;

  @include Responsive(SM) {
    position: unset;
  }
}

.labelWrapper {
  width: $labelWidth;
}
.formTitle {
  @include sharedForm_formTitle;
}
.label {
  @include sharedForm_label;
}

.inputField {
  @include sharedForm_inputField;
}

.sup {
  @include sharedForm_sup;
}

:global(#app) {
  .customedSegment {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3em;
    width: 12rem;
  }
  .editBtn {
    @include sharedForm_editBtn;
  }
  .btnCancel {
    @include sharedForm_btnCancel;
    width: 12rem;

    @include Responsive(SM) {
      width: 10rem;
      height: 4rem;
    }
  }
  .btnSave {
    @include sharedForm_btnSave;
    width: 12rem;

    @include Responsive(SM) {
      width: 10rem;
      height: 4rem;
      padding: 0;
    }
  }
  .active {
    @include sharedForm_activeSaveBtn;
  }

  .userIcon {
    font-size: 2.5rem;
    color: $gray_MPB;
  }
}

.rightGroup {
  @include sharedForm_rightGroup;
  width: 20%;
}

.message {
  @include sharedForm_dangerMessage;
}

.userNameText {
  @include overline;
  font-size: 16px;
  margin: 0;
}

.userRoleText {
  @include body1;
  color: $gray_MPB;
  text-align: center;
}
