@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';
@import '../../theme/sharedForm';
@import '../../theme/responsive';

.formWrapper {
  @include sharedForm_formWrapper;
  width: 90%;
  position: relative;
  display: flex;
  max-width: 1200px;
  padding: 1rem 1.6rem 3rem 1.6rem;
  @include Responsive(MD) {
    width: 100%;
  }
  form {
    width: 80%;
    @include Responsive(MD) {
      width: 100%;
    }
  }
}

.formField {
  @include sharedForm_formField;
  align-items: center;
}

.formRow {
  position: relative;
}

.widthFirstName {
  width: 42%;
}

.positioningInput {
  @include sharedForm_positioningInput;
}

.labelWrapper {
  min-width: $labelWidth;
}
.formTitle {
  @include sharedForm_formTitle;
}
.label {
  @include sharedForm_label;
}

.inputField {
  @include sharedForm_inputField;
}

.widthFirstName {
  width: 42%;
}

.sup {
  @include sharedForm_sup;
}

:global(#app) {
  .customedSegment {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3em;
    width: 12rem;
  }
  .editBtn {
    @include sharedForm_editBtn;
  }
  .btnCancel {
    @include sharedForm_btnCancel;
    width: 12rem;
  }
  .btnSave {
    @include sharedForm_btnSave;
    width: 12rem;
    margin-bottom: 1rem;
    @include Responsive(LG) {
      margin-bottom: 0;
    }
  }
  .active {
    @include sharedForm_activeSaveBtn;
  }

  .userIcon {
    font-size: 2.5rem;
    color: $gray_MPB;
  }

  .pencilIcon {
    @include sharedForm_pencilIcon;
  }
}

.rightGroup {
  @include sharedForm_rightGroup;
  width: 20%;
}

.message {
  @include sharedForm_dangerMessage;
}

.userNameText {
  @include overline;
  font-size: 16px;
  margin: 0;
}

.userRoleText {
  @include body1;
  color: $gray_MPB;
  text-align: center;
}
