@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/responsive';
@import '../../theme/button';
:global(#app) {
  .submitButton {
    @include submit_btn_override_semantic;
    width: 100%;
    margin: 0;
    margin-top: 3.5rem;
    padding: 16px 10px;
    color: $darkest_gray;
    background-color: $gray_MPB;
    @include Responsive(SM) {
      margin-top: 2rem;
    }
  }

  .btnActive {
    @include primary_btn;
    // background-color: $darkest_gray;
    color: $white1;
    // &:hover {
    //   background-color: $red1;
    // }
  }

  .formBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .formField {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    align-items: center;

    .inputField {
      border: none;
      padding: 0;
      padding-bottom: 1rem;
      font-size: 18px;
      font-family: $primary_font5;
      border-bottom: 1px solid $gray_MBP_light;
      border-radius: 0;
      &:focus {
        border-bottom: 1px solid $darkest_gray;
      }
    }
  }
}

.title {
  @include body1;
  font-weight: 700;
  margin: 3px 0 !important;
  letter-spacing: 0.75px;
  color: $darkest_gray;
}

.titleDesc {
  @include body2;
}
.userEmail {
  @include body2;
  font-weight: 700;
  color: $red1;
}

.errorMessage {
  margin-top: 0.7rem;
  margin-left: 0.2rem;
  border-radius: 0.28571429rem;
  position: relative;
  color: #ff0000;
  width: 100%;
  text-align: center;
  padding: 3px 0;
  @include button;
}

.ruleWrapper {
  border: 1px solid $gray_MPB;
  border-radius: 8px;
  text-align: start;
  position: absolute;
  left: -104%;
  top: 20%;
  @include Responsive(SM) {
    position: relative;
    left: 0;
    top: 0;
    margin-top: 0.5rem;
  }

  .ruleTitle {
    color: $gray_MPB;
    padding: 8.29px 5.73px 5px 18.94px;
    border-bottom: 1px solid $gray_MPB;
    margin-bottom: 0;
    @include body3;
    font-weight: 600;
    font-size: 20px;
  }
  .ruleContainer {
    padding: 8.29px 5.73px 10.58px 18.94px;
    .rule {
      @include body3;
      color: $gray_MPB;
      font-style: italic;
      font-weight: 600;
      position: relative;
      margin: 0;
      &::before {
        content: '';
        height: 8px;
        width: 8px;
        background-color: $gray_MPB;
        position: absolute;
        top: 37%;
        left: -12px;
        border-radius: 50%;
      }
    }
    .ruleCorrect {
      color: $darkest_gray;
      &::before {
        background-color: $green1;
      }
    }
  }
}
