@import '../../theme/palette';
@import '../../theme/text';

.wrapper {
  padding-right: 1rem;
}

.topContentBlock {
  min-height: 10rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.midContentBlock {
  min-height: 10rem;
  padding: 1rem 0;
}

.bottomContentBlock {
  padding: 1rem 0;
}

.text1 {
  font-family: $primary_font1;
  color: $darkest_gray;
}

.contentTitle {
  font-family: $primary_font1;
  font-size: 18px;
  font-weight: 700;
  color: $red_MPB;
}
