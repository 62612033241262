@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';
@import '../../theme/responsive';

/*PLANNED SESSION*/

:global(#app) {
  .joinBtn {
    margin-top: 1rem;
    @include primary_btn;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .coacheeDiagnosisBtn {
    margin-top: 1rem;
    @include secondary_btn;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .videoIcon {
    font-size: 45px;
    color: $red1;
  }
  .saveNoteBtn {
    @include primary_btn;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .endSessionBtn {
    @include tertiary_btn;
  }
  .coachAbsentBtn {
    @include tertiary_btn;
    background-color: $red_MPB;
    span {
      color: $white1;
    }
  }
  .reEvaluateBtn {
    @include secondary_btn;
  }
  .setObjectiveBtn {
    @include secondary_btn;
  }
}

.headerWrapper {
  border-bottom: 2px solid $gray_MBP_light;
  padding: 1.5rem 1.2rem;
}

.sessionTitle {
  font-family: $primary_font1;
  font-size: 32px;
  color: $darkest_gray;
  margin-bottom: 0;
}

.plannedHeader {
  display: flex;
  justify-content: space-between;
  align-self: center;
  border-bottom: 2px solid $gray_MBP_light;
  padding: 1.5rem 1.2rem;

  @include Responsive(MD) {
    flex-direction: column;
    align-items: center;
  }
}

.headerCoachAndNumberSession {
  display: flex;
  justify-content: center;
  align-items: center;

  @include Responsive(MD) {
    margin-bottom: 1rem;
  }
}

.boldSession {
  @include StronglyEmphasize;
  font-size: 24px;
  letter-spacing: 1px;
  color: $darkest_gray;

  @include Responsive(LG) {
    font-size: 20px;
  }
}

.text1 {
  @include StronglyEmphasize;
  font-size: 18px;
  color: $gray_MPB;
  padding-left: 2rem;
  span {
    @include StronglyEmphasize;
    font-size: 18px;
    color: $darkest_gray;
  }
}

.text2 {
  @include overline;
  font-size: 24px;
  color: $gray_MPB;
  &:last-child {
    padding-left: 1rem;
  }
  span {
    @include overline;
    font-size: 24px;
    color: $darkest_gray;
    padding-left: 0.5rem;
  }
}

.mainContentBlock {
  padding: 0 2rem;
}

.meetingLinkBlock {
  width: 100%;
  margin-top: 1.2rem;
}

.topMeetingBlock {
  display: flex;
  align-items: center;
}

.bottomMeetingBlock {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.linkTitle {
  @include StronglyEmphasize;
}

.linkText {
  color: $gray2;
  cursor: pointer;
  &:hover {
    color: $darkest_gray;
    text-decoration: underline;
  }
}

.text3 {
  @include head1;
  color: $gray_MPB;
  font-style: italic;
  margin-top: 1.7rem;
  margin-bottom: 0;
}

.text3Alt {
  @include head1;
  color: $gray1;
  margin-top: 1.7rem;
  margin-bottom: 0;
}

.textMessage {
  @include head1;
  font-style: italic;
  color: $red1;
  margin-bottom: 0;
  min-height: 2rem;
}

.noteFrame {
  width: 100%;
  border: 1px solid $gray_MPB;
}

.noteArea {
  height: 40vh;
  min-height: 470px;
  width: 100%;
  border: none;
  padding: 1rem;
  resize: none;
  &:focus {
    outline: none;
  }
}

.noteBtnBlock {
  text-align: right;
  padding: 1rem 1rem;
}

.endSessionBtnBlock {
  margin-top: 1rem;
  text-align: right;
  padding: 0 1rem;
}
