@import './palette';
@import './text';

@mixin primary_btn {
  border-radius: 5px;
  background-image: linear-gradient(to bottom, $gray1, $gray1);
  color: $white1;
  transition: background-image 0.3s;
  &:hover {
    background: linear-gradient(90deg, $gray2 0%, $red_MPB 100%);
    color: $white1;
  }
}

@mixin secondary_btn {
  border: 1px solid $darkest_gray;
  color: $darkest_gray;
  border-radius: 5px;
  background: $white1;
  transition: background 0.3s;
  &:hover {
    border: unset;
    background: linear-gradient(90deg, $gray2 0%, $red_MPB 100%);
    color: $white1;
    span {
      color: $white1;
    }
  }
}

@mixin tertiary_btn {
  border: 1px solid $red_MPB;
  color: $red_MPB;
  border-radius: 5px;
  background: $white1;
  transition: background 0.3s;
  &:hover {
    background: $red_MPB;
    border: 1px solid $red_MPB;
    color: white;
  }
}

@mixin primary_disabled_btn {
  border: transparent;
  color: $gray_MPB;
  background-color: $gray_MPB_lightest;
  border-radius: 5px;
  transition: all 0.3s;
}

@mixin submit_btn_override_semantic {
  color: $white1;
  font-family: $primary_font5;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 5px;
}
