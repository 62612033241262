@import './palette';

$primary_font1: ManropeRegular;
$primary_font2: ManropeSemiBoldItalic;
$primary_font3: ManropeSemiBold;
$primary_font4: ManropeBold;
$primary_font5: ManropeExtraLight;
$secondary_font1: Inter;

@mixin h1Headline {
  font-family: $primary_font1;
  font-weight: 400;
  font-size: 64px;
}

@mixin h2Headline {
  font-family: $primary_font1;
  font-weight: 400;
  font-size: 24px;
}

@mixin h4Headline {
  font-family: $primary_font1;
  font-weight: bold;
  font-size: 14px;
}

@mixin h5Headline {
  font-family: $primary_font4;
  font-weight: bold;
  font-size: 14px;
}

@mixin head3 {
  font-family: $primary_font1;
  font-weight: 400;
  font-size: 32px;
}

@mixin head2 {
  font-family: $primary_font1;
  font-weight: 700;
  font-size: 18px;
}

@mixin head1 {
  font-family: $primary_font1;
  font-weight: 400;
  font-size: 18px;
}

@mixin emphasize {
  font-family: $primary_font2;
  font-weight: 600;
  font-size: 10.5px;
}

@mixin StronglyEmphasize {
  font-family: $primary_font1;
  font-weight: 700;
}

@mixin body1 {
  font-family: $primary_font1;
  font-weight: 400;
}

@mixin body2 {
  font-family: $primary_font1;
  font-weight: 400;
  font-size: 14px;
}

@mixin body3 {
  font-family: $primary_font5;
  font-weight: 300;
  font-size: 18px;
}

@mixin subtitle {
  font-family: $primary_font1;
  font-weight: 400;
  font-size: 14px;
  color: $gray_MPB;
}

@mixin smallCaption {
  font-family: $primary_font1;
  font-weight: 400;
  font-size: 10px;
}

@mixin mediumCaption {
  font-family: $primary_font5;
  font-weight: 400;
  font-size: 13px;
}

@mixin button {
  font-family: $primary_font3;
  font-weight: 600;
  font-size: 11px;
}

@mixin overline {
  font-family: $primary_font1;
  font-weight: 600;
  font-size: 11px;
}

@mixin overlineBold {
  font-family: $primary_font4;
  font-weight: 700;
  font-size: 11px;
}

@mixin statisticNumber1 {
  font-family: $secondary_font1;
  font-size: 24px;
  color: $red1;
  font-weight: bold;
}

@mixin statisticNumber2 {
  font-family: $secondary_font1;
  font-size: 96px;
  color: $red1;
  font-weight: bold;
}

@mixin statisticTotal {
  font-family: $secondary_font1;
  font-size: 64px;
  font-weight: 700;
}

@mixin textLegend {
  font-family: $primary_font1;
  font-weight: 700;
  font-size: 14px;
}
