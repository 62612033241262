@import '../../theme/palette';
@import '../../theme/text';

:global(#app) {
  .messageItem {
    max-width: 482px;
    background-color: $gray_MPB;
    border-radius: 10px;
    box-shadow: none;
    border: none;
    opacity: 0.9;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .myMessage {
    background: $red1;
  }

  .messageIcon {
    color: $white1;
  }
}

.wrapper {
  padding: 1rem 0;
}

.messageItemsBlock {
  display: flex;
}

.itemPositionRight {
  justify-content: flex-end;
}

.messageItemRow {
  margin: 1rem 0;
  display: flex;
  align-items: center;
}

.myMessagePosition {
  justify-content: flex-end;
}

.myMessageTimePosition {
  text-align: right;
  padding-right: 5px;
}

.messageTitle {
  font-family: $primary_font1;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 5px;
  word-break: break-word;
  color: $white1;
}

.messageContentArea {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  word-break: break-word;
  color: $white1;
}

.bottomIconBlock {
  width: 100%;
  text-align: right;
}

.sentTimeText {
  font-family: $primary_font1;
  font-size: 13px;
  font-style: italic;
  color: $gray_MPB;
}
