@import '../../../theme/palette';
@import '../../../theme/text';

:global(#app) {
  .btn {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    background-color: $white1;
    border: 1px solid $darkest_gray;
    transition: all 0.4s;

    span {
      @include button;
      font-size: 14px;
    }

    @media screen and (max-width: 1363px) {
      margin-bottom: 5px;
    }
  }

  .btnAssign,
  .btnUnassign {
    &:hover {
      background-color: $red1;
      border-color: $red1;
      color: $white1;
    }
  }

  .btnRemove {
    color: $red_MPB;
    border-color: $red_MPB;
    &:hover {
      background-color: $red_MPB;
      color: $white1;
    }
  }
}

.wrapper {
  display: inline;
}
