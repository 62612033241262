@import '../../theme/palette';
@import '../../theme/text';
.wrapper {
  border: 1px solid $gray_MBP_light;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-width: 12.4rem;
  position: absolute;
  right: 100%;
  top: 72%;
  z-index: 99;
}

.textItem {
  font-family: $primary_font1;
  text-align: left;
}

:global(#app) {
  .customedSegment {
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0.5em 1em;
    &:hover {
      background-color: $red1;
      cursor: pointer;
      .textItem {
        color: $white1;
      }
    }
  }
}
