@import './text';
@import './responsive';
@import './palette';

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.topLevelText {
  text-transform: uppercase;
}
.secondLevelText {
  text-transform: capitalize;
}

.logo {
  width: 17rem;
}

.logoPosition_normal {
  margin: 1.5rem 0 0 2rem;
  @media only screen and (max-width: $SM) {
    margin: 1rem 0 0 0.2rem;
  }
}

/*STEPPER*/

.bar {
  background-color: $red1 !important;
  border-radius: 12px !important;
  height: 0.6rem !important;
}

.buttonText {
  @include head2;
  line-height: 20px;
}
.secondary_button_text {
  @include head2;
  font-size: 14px;
}

.tableListRow {
  &:nth-child(odd) {
    background-color: $white1;
  }
  &:nth-child(even) {
    background-color: $gray_MPB_lightest;
  }
}

.textDangerFormMessage {
  font-family: $primary_font1;
  color: $red_MPB;
  font-size: 18px;
}

.textInfoFormMessage {
  font-family: $primary_font1;
  color: $darkest_gray;
  font-size: 18px;
}

.readDataForm {
  font-family: $primary_font1;
}

.hideUnderTablet {
  @include Responsive(MD) {
    display: none;
  }
}
