@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';

/*NOTE*/

:global(#app) {
  .noteBlock {
    border: 2px solid $gray_MBP_light;
    border-radius: 10px;
    padding: 0;
  }

  .icon1 {
    font-size: 18px;
  }
  .icon2 {
    color: $gray_MPB;
    cursor: pointer;
  }
}
.topNoteBlock {
  padding: 1.2rem 1.5rem;
  display: flex;
  justify-content: space-between;

  span {
    @include overline;
    color: $gray_MPB;
    font-size: 14px;
  }
}

.borderBottom {
  border-bottom: 2px solid $gray_MBP_light;
}

.bottomNoteBlock {
  padding: 1.2rem 1.5rem;
}

.noteText {
  @include body1;
  font-size: 14px;
}
