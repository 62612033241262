@import '../../theme/palette';
@import '../../theme/text';

/*BAR*/
.barWrapper {
  width: 100%;
  height: 100%;
}

/*LINE*/

.lineWrapper {
  min-height: 313px;
  padding: 0 7px 10px 7px;
}

/*SIMPLE HORIZONTAL BAR*/

.simpleHorizontalTotalBlock {
  padding: 1rem 0 1.5rem 0;
}

.simpleHorizontalLegendBlock {
  display: flex;
  flex-wrap: wrap;
}

.firstSimpleHorizontalLegendItem {
  margin-right: 2rem;
}

/* HORIZONTAL STACKED BAR*/

.topBlock {
  display: flex;
  align-items: center;
}

.totalBlock {
  color: $darkest_gray;
  display: flex;
  flex-direction: column;
  margin: 2rem 1rem 1rem 0;
}
.legendBlock {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 1rem;
  color: $darkest_gray;
}

.legendBlock_topRow {
  width: 100%;
  margin-bottom: 1rem;
}
.legendBlock_botRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.totalNumber {
  font-family: $secondary_font1;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.totalText {
  @include body1;
  font-size: 18px;
  &::before {
    content: ' ';
  }
}

.fullBar {
  width: 100%;
  background-color: $gray_MPB_lightest;
  height: 21px;
  border-radius: 10px;
  margin: 0.2rem 0 0.5rem 0;
  border: 1px solid $gray_MBP_light;
}

.startedAndDone {
  height: 100%;
  background-color: $red1;
  border-radius: 10px;
}

.done {
  height: 100%;
  background-color: $gray_MPB;
  border-radius: 10px;
}

.legendItem {
  display: flex;
  align-items: center;
}

.bar_legendColor {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  margin-right: 0.5rem;
}

.doneColor {
  background-color: $gray_MPB;
}

.startedColor {
  background-color: $red1;
}

.unassignedColor {
  background-color: $gray_MBP_light;
}

.legendText {
  @include body1;
  font-size: 16px;
}
.legendNumber {
  font-family: $secondary_font1;
  font-size: 24px;
  font-weight: 700;
  &::after {
    content: ' ';
  }
}
