@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/responsive';

.wrapper {
  padding: 1rem 1.5rem;
}

.toolsBlock {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .leftBlock {
    display: flex;
    align-items: center;

    @include Responsive(SM) {
      display: block;
      width: 100%;
    }
  }
}

.timeDropdownLabel {
  font-family: $primary_font1;
  margin-right: 0.5rem;
  color: $darkest_gray;

  @include Responsive(SM) {
    display: block;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

.timeDropdown {
  @include Responsive(SM) {
    width: 100%;
  }
}

.monthBlock {
  margin-top: 1.2rem;
}

.monthTitle {
  font-family: $secondary_font1;
  font-size: 30px;
  font-weight: 700;
  color: $red1;
  text-align: center;
  margin-bottom: 0;
}

.dateBlock {
  &:not(:first-of-type) {
    margin-top: 1rem;
  }
}

.dateTitle {
  font-family: $secondary_font1;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0;
}
