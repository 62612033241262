@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';
@import '../../theme/button';
@import '../../theme/responsive';

.wrapper {
  @include sharedMainContent_wrapper;
}

.wrapper2 {
  padding: 3rem 1.2rem 2rem 3rem;
}

.title {
  @include sharedMainContent_title;
}

.titleDesc {
  @include sharedMainContent_titleDesc;
}

.cardsWrapper {
  width: 100%;
  margin-top: 1.5rem;
}

.supportText {
  font-family: ManropeExtraLight;
  font-weight: 400;
  color: $gray2;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  a {
    padding-left: 5px;
    @include Responsive(MD) {
      padding-left: 0;
    }
  }
}
.emailSupport {
  font-weight: 700;
  text-decoration: underline;
  color: $red1;
}
/*BUTTON*/

.bottomButtonWrapper {
  text-align: right;
  p {
    @include subtitle;
  }
}

:global(#app) {
  .submitButton {
    @include primary_disabled_btn;
    width: 12rem;
    height: 3.5rem;
    margin-top: 2rem;
    margin-bottom: 5px;
    span {
      @include head2;
    }
  }

  .active {
    @include primary_btn;
  }

  .closeBtn {
    @include primary_btn;
    width: 17rem;
    height: 3rem;
  }

  .infoIcon {
    color: $gray_MPB;
    width: 30px;
    height: 30px;
    border: 2px solid $gray_MPB;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }

  .customedSegment {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border: 4px solid $white1;
    border-radius: 8px;
    padding: 1.2rem 1.2rem 3rem 1.2rem;
  }

  .checkIcon {
    color: $green1;
    margin-bottom: 1rem;
  }
}

.success_contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.successText {
  @include StronglyEmphasize;
  margin-bottom: 1rem;
}

.descText {
  @include body1;
  margin-bottom: 2rem;
}

.coachName {
  font-weight: 700;
  color: $red1;
}
