@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';

:global(#app) {
  .checkIcon {
    color: $green1;
  }
  .nextBtn {
    @include primary_btn;
    width: 10rem;
  }
}

.marginBottom {
  margin-bottom: 30px;
}

.topBlock {
  margin: 2rem 0;
  text-align: center;
}

.collectionBlock {
  display: flex;
  align-items: baseline;
}

.answer {
  @include body1;
  font-size: 18px;
  margin-bottom: 1.2rem !important;
  padding: 0 1rem;
}

.question {
  @include h2Headline;
  font-weight: 700;
  font-size: 20px;
  margin-top: 1rem !important;
  &:first-child {
    margin-bottom: 0.5rem;
  }
}

.multiAnswerContainer {
  padding: 0 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.multiAnswer {
  display: flex;
  align-items: center;
}

.dateAnswer {
  @include body1;
  font-size: 18px;
}

.checkbox_answer {
  @include body1;
  font-size: 18px;
}

/*BUTTON*/
.buttonBlock {
  text-align: right;
  margin-top: 1rem;
}

/* ResultCheckBox */

.checkBox {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid $gray_MPB;
  border-radius: 2px;
  margin-right: 1rem;
  padding: 1px;
  min-width: 1.5rem;
}

.checkBox_inner {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.bg-default {
  background: $secondary_blue3;
}

.bg-red {
  background: $red_MPB;
}

.bg-yellow {
  background: $yellow1;
}

.bg-green {
  background: $green1;
}

.bg-blue {
  background: $secondary_blue2;
}
