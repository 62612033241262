.App {
}

@font-face {
  font-family: 'ManropeRegular';
  src: local('Manrope'), url(./assets/font/Manrope/Manrope-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'ManropeSemiBoldItalic';
  src: local('Manrope'), url(./assets/font/Manrope/Manrope-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'ManropeSemiBold';
  src: local('Manrope'), url(./assets/font/Manrope/Manrope-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'ManropeBold';
  src: local('Manrope'), url(./assets/font/Manrope/Manrope-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'ManropeExtraLight';
  src: local('Manrope'), url(./assets/font/Manrope/Manrope-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/font/Inter/Inter.ttf);
}

/* Utilities - Tailwind inspired */
.relative {
  position: relative;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}
