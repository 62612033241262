@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';

:global(#app) {
  .sendBtn {
    width: 10rem;
    @include primary_disabled_btn;
  }

  .active {
    @include primary_btn;
  }

  .closeIcon {
    font-size: 1.7rem;
    color: $white1;
    position: absolute;
    right: 0;
    top: 5px;
    cursor: pointer;
  }
  .starIcon {
    color: $other_secondary_yellow;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  opacity: 1;
  display: flex;
  justify-content: center;
}

.wrapper {
  text-align: center;
  width: 80%;
  max-width: 48rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border: 1px solid $gray_MPB;
  position: absolute;
  top: 20%;
  background-color: $white1;
}

.headingBlock {
  height: 4rem;
  background-color: $red1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.headerTitle {
  @include h2Headline;
  font-weight: 700;
  color: $white1;
}

.bodyBlock {
  padding: 1.2rem;
}

.contentText {
  @include h2Headline;
  font-weight: 700;
  color: $darkest_gray;
}

.ratingBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.ratingText {
  @include overline;
  font-size: 20px;
  font-weight: 400;
  color: $darkest_gray;
}

.ratingComp {
  margin: 0 2rem;
}

.btnBlock {
  text-align: right;
}

.btnBlock2 {
  margin-top: 2rem;
}

.finalEvaluateNumber {
  font-family: $secondary_font1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 1rem;
}
.ratingNumber {
  font-size: 48px;
}
.maxRatingNumber {
  font-size: 36px;
}

.thanksText {
  @include StronglyEmphasize;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 2rem;
}
