@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';
@import '../../theme/sharedForm';
@import '../../theme/responsive';

:global(#app) {
  .editBtn {
    @include sharedForm_editBtn;
  }

  .btnCancel {
    @include sharedForm_btnCancel;
    margin-top: 1rem;
    width: $form_save_cancel_btn_width;
    @include Responsive(LG) {
      margin-top: 0;
    }
  }
  .btnSave {
    @include sharedForm_btnSave;
    width: $form_save_cancel_btn_width;
  }

  .active {
    @include sharedForm_activeSaveBtn;
  }
  .disable {
    @include primary_disabled_btn;
  }

  .rightIcon {
    color: $gray_MPB;
    cursor: pointer;
    text-align: left;
  }

  .checkbox {
    label {
      @include body1;
      font-size: 16px;
    }
  }

  .radioBox {
    label {
      @include body1;
      font-size: 16px;
    }
  }
  .thematicQuestion {
    margin-bottom: 1.5rem;
    label {
      font-size: 18px;
    }
  }

  .pencilIcon {
    @include sharedForm_pencilIcon;
  }
}

.formWrapper {
  margin-top: 1.2rem;
  position: relative;
  @include sharedForm_formWrapper;
}

.formTitle {
  @include sharedForm_formTitle;
}

.formContainer {
  display: flex;
  @include Responsive(LG) {
    display: block;
  }
}

.formCreateCoach {
  width: 70%;
  @include Responsive(LG) {
    width: 100%;
    padding-top: 1rem;
  }
  .formRow {
    position: relative;
  }
  .formField {
    @include sharedForm_formField;
    align-items: center;
    .w42 {
      width: 42%;
    }
    .w66 {
      width: 66%;
      @include Responsive(LG) {
        width: 100%;
      }
    }
    .labelWrapper {
      width: 30%;
      .label {
        @include body1;
        font-weight: 600;
      }
    }
    .radioLabel {
      width: 100%;
    }
  }
  .introductionFormField {
    align-items: flex-start;
  }

  .noBorderBottom {
    border-bottom: none;
  }

  .positioningInput {
    position: absolute;
    bottom: 0;
    right: 0;
    @include Responsive(LG) {
      position: relative;
    }
  }

  .sup {
    color: $red1;
    font-size: 18px;
    font-weight: bold;
    top: 0;
  }
  .inputField {
    @include sharedForm_inputField;
  }
}

.checkboxWrapper {
  width: 70%;
}

.radioWrapper {
  margin-top: 1rem;
}

.languagesViewItem {
  font-family: $primary_font1;
  color: $darkest_gray;
  margin-right: 1rem;
}

.certificationViewItem {
  font-family: $primary_font1;
  color: $darkest_gray;
  margin-right: 1rem;
}

/**RIGHT GROUP**/

.rightGroup {
  @include sharedForm_rightGroup;
  width: 30%;
  padding-top: 2rem;
  justify-content: flex-start;
  .btnGroup {
    text-align: center;
    display: flex;
  }
  @include Responsive(LG) {
    margin-top: 0;
  }
}
.textErrorMessage {
  @include sharedForm_dangerMessage;
}

/*MANUAL GUIDE*/

.instructionWrapper {
  margin-top: 1.2rem;
}

.instructionDropDown {
  display: flex;
  align-items: center;
}

.instructionTextBlock {
  @include StronglyEmphasize;
  font-size: 18px;
  background-color: $gray_MBP_light;
  width: 100%;
  padding: 0.4rem 0.5rem;
}

/*THEMATICS*/

.thematicsWrapper {
  margin-top: 1.2rem;
}

.thematicsTitle {
  @include StronglyEmphasize;
  font-size: 18px;
  padding: 0.5rem 1rem 0.5rem 3.2rem;
  background-color: $darkest_gray;
  color: $white1;
}

.thematicsQuestionWrapper {
  margin-top: 1.2rem;
  padding: 1.5rem 0;
  border: 1px solid $gray_MBP_light;
  border-radius: 10px;
}

.questionCategory {
  @include StronglyEmphasize;
  font-size: 18px;
  border-bottom: 1px solid $gray_MBP_light;
  padding: 0 1.5rem 1rem 1.5rem;
}

.thematicsQuestionBlock {
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
}

.presentationTextArea {
  font-family: $primary_font1;
  border: 1px solid $gray_MPB;
  border-radius: 5px;
  width: 70%;
  height: 13.8rem;
  padding: 1rem;
  resize: none;
  color: $darkest_gray;
  &:focus {
    outline: none;
  }
  @include Responsive(LG) {
    width: 100%;
    margin-top: 12px;
  }
}

.uploadBlock {
  margin-top: 1rem;
  @include Responsive(LG) {
    margin-top: 0;
  }
}

.previewWrapper {
  margin-top: 2.2rem;
}

.photoPositionRight {
  @include Responsive(LG) {
    display: none;
  }
}

.photoPositionLeft {
  display: none;
  @include Responsive(LG) {
    display: block;
  }
  max-width: 190px;
}

.tokenWarning {
  margin: 0;
  color: $red_MPB;
  font-family: $primary_font1;
  font-size: 15px;
  font-style: italic;
}

.alignTop {
  align-items: flex-start !important;
}
