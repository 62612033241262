@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';
@import '../../theme/button';

.wrapper {
  @include sharedMainContent_wrapper;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
}

.programWrapper {
  padding: 0 1.2rem;
  &:not(:first-child) {
    margin: 2rem 0 1rem 0;
  }
}

.programName {
  @include sharedMainContent_title;
  margin-bottom: 1.2rem;
}
.programNameDesc {
  @include sharedMainContent_titleDesc;
}
.programDescHighlight {
  all: inherit;
  display: inline;
  font-weight: 700;
  &::before {
    content: ' ';
  }
}

.courseWrapper {
  padding: 0 2rem;
  tr {
    td {
      @include head1;
      padding: 0.8rem;
      color: $gray1;
      min-width: 80px;
      text-align: left;
      &:first-child {
        text-align: right;
        padding-right: 1rem;
      }
      &:last-child {
        color: $red1;
        font-family: $secondary_font1;
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
}
.numberOfSession {
  text-align: right;
}

:global(#app) {
  .customedSegment {
    @include sharedMainContent_customedSegment;
    max-width: 100rem;
  }
  .btn {
    height: 3.5rem;
    max-height: 4rem;
  }
  .active {
    @include primary_btn;
  }
  .disable {
    @include secondary_btn;
  }

  .btnEvaluate {
    @include primary_btn;
    margin-top: 0.5rem;
  }
}

.closedBtnText {
  color: $darkest_gray;
}
.courseName {
  @include h2Headline;
  font-weight: 700;
  text-transform: capitalize;
}

.btnText {
  @include StronglyEmphasize;
  font-size: 18px;
}

.anchorTopRight {
  position: absolute;
  right: 0;
  top: 0;
}
