@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';

.contactBlock {
  .container {
    padding: 1rem 0.2rem;
    background-color: white;
  }

  p {
    margin-bottom: 2px;
  }

  p,
  span,
  .phoneNumber {
    font-family: $primary_font3;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }

  img {
    margin-left: 10px;
    width: 8rem;
    margin-bottom: 0.5rem;
  }

  .logo {
    width: 156px;
    transform: translate(7px, 2px);
  }

  .phoneNumberBlock {
    background-color: $gray2;
    width: 95%;
    padding: 10px;
    border-radius: 10rem;
    margin: 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .phoneNumber,
    span {
      color: white;
    }

    > span {
      border: 3px solid white;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      display: inline-block;
      margin-right: 0.5rem;
    }
  }

  .phoneIcon {
    transform: rotate(96deg) translate(5px, -1px);
    font-size: 22px;
  }
}

.flag {
  height: 401px;
  position: relative;
  border-left: 2px solid $gray_MBP_light;
  border-top: 2px solid $gray_MBP_light;
}

.flag:before,
.flag:after {
  content: '';
  position: absolute;
  height: 50%;
  width: 51.3%;
  bottom: -14px;
  z-index: -2;
  border-bottom: 2px solid $gray_MBP_light;
  background-color: white;
}

.flag:before {
  left: -4px;
  /*width of border*/
  border-left: 2px solid $gray_MBP_light;
  transform: skewY(-20deg);
  -webkit-transform: skewY(-20deg);
}

.flag:after {
  right: -4px;
  /*width of border*/
  border-right: 2px solid $gray_MBP_light;
  -webkit-transform: skewY(20deg) translateX(-2px);
  transform: skewY(20deg) translateX(-2px);
}

@media screen and (max-width: 1917px) {
  .flag:before,
  .flag:after {
    bottom: -20px;
  }
}

@media screen and (max-width: 1836px) {
  .phoneIcon {
    font-size: 18px;
  }

  .flag:before,
  .flag:after {
    width: 51.4%;
  }
}

@media screen and (max-width: 1862px) {
  .contactBlock {
    .phoneNumberBlock {
      .phoneNumber {
        font-size: 15px;
      }

      > span {
        border: 2px solid white;
        height: 35px;
        width: 35px;
        margin-right: 0.2rem;
      }
    }

    .phoneIcon {
      transform: rotate(96deg) translate(6px, -3px);
      font-size: 17px;
    }
  }

  .flag:before,
  .flag:after {
    bottom: -40px;
  }
}

@media screen and (max-width: 1698px) {
  .contactBlock {
    .phoneNumberBlock {
      .phoneNumber {
        font-size: 14px;
      }

      > span {
        height: 25px;
        width: 25px;
        margin-right: 0.2rem;
      }
    }

    .phoneIcon {
      transform: rotate(96deg) translate(2px, -5px);
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 1550px) {
  .flag:before,
  .flag:after {
    width: 51.5%;
  }
}

@media screen and (max-width: 1470px) {
  .contactBlock {
    .phoneNumberBlock {
      flex-direction: column;

      > span {
        display: none;
      }
    }

    .logo {
      width: 150px;
      transform: translate(2px, 2px);
    }
  }

  .flag:before,
  .flag:after {
    bottom: -20px;
    width: 51.8%;
  }
}

@media screen and (max-width: 1321px) {
  .flag:before,
  .flag:after {
    width: 52%;
    bottom: -40px;
  }
}

@media screen and (max-width: 1304px) {
  .contactBlock {
    .phoneNumberBlock {
      width: 100%;
    }
  }
  .flag:before,
  .flag:after {
    bottom: -50px;
  }
}

@media screen and (max-width: 1249px) {
  .contactBlock {
    .logo {
      width: 136px;
    }
  }

  .flag:before,
  .flag:after {
    bottom: -50px;
  }
}

@media screen and (max-width: 1207px) {
  .flag:before,
  .flag:after {
    bottom: -70px;
  }
}

@media screen and (max-width: 1199px) {
  .contactBlock {
    .phoneNumberBlock {
      flex-direction: row;

      > span {
        display: inline-block;
      }
    }

    .logo {
      transform: translate(4px, 2px);
    }
  }

  .flag:before,
  .flag:after {
    bottom: 0;
    width: 51.3%;
  }
}

@media screen and (max-width: 1119px) {
  .flag:after {
    transform: skewY(20deg) translateX(-2.5px);
  }
  .flag:before,
  .flag:after {
    bottom: -20px;
  }
}

@media screen and (max-width: 991px) {
  .contactBlock {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    p,
    span {
      font-size: 17px;
    }

    .phoneNumberBlock {
      flex-direction: row;
      width: 90%;

      > span {
        display: inline-block;
        margin-right: 0.5rem;
      }

      .phoneNumber {
        font-size: 17px;
      }
    }

    .logo {
      width: 160px;
      transform: translate(10px, 4px);
    }
  }

  .flag {
    border: 2px solid $gray_MBP_light;
    width: 300px;
    height: auto;
    margin: 0 auto;
  }

  .flag:before,
  .flag:after {
    display: none;
  }
}
