@import '../../theme/palette';
@import '../../theme/text';

:global(#app) {
  .wrapper {
    height: 100%;
  }
  .customedImg {
    padding: 0.5em;
    background-color: $white1;
  }

  .customSegment {
    height: 100%;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    transform: transition all 0.4s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
      border-radius: 8px;
      border: 5px solid $red1;
      transform: translateY(-5px);
    }
  }
}

.contentWrapper {
  height: 100%;
  padding-top: 5%;
}

.headerBlock {
  padding: 0 0 2rem 0;
  text-align: center;
}

.textNormal {
  @include head3;
  margin: 0;
  font-size: 48px;
  color: $darkest_gray;
}

.highLight {
  @include head3;
  color: $secondary_blue2;
}

.rolesBlock {
  padding: 2rem 0;
  width: 100%;
  margin: 0 auto;
}

.roleItem {
  width: 221px;
  height: 197px;
  margin: 0 auto;
  border: 5px solid $white1;
}

.card {
  cursor: pointer;
}

.titleBlock {
  height: 50px;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemTitle {
  @include StronglyEmphasize;
  color: $red1;
  text-align: center;
}

.imageSvg {
  margin: 0 auto;
  width: 130px;
  height: 125px;
}
