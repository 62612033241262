@import '../../theme/text';
@import '../../theme/palette';
@import '../../theme/button';

:global(#app) {
  .submitButton {
    @include submit_btn_override_semantic;
    width: 100%;
    margin: 4.22rem 0 0 0;

    .formBlock {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .disableBtn {
    @include primary_disabled_btn;
  }
  .activeBtn {
    @include primary_btn;
  }
  input:-webkit-autofill {
    border-color: black !important;
  }
  .formField {
    width: 100%;
    margin: 1.2rem 0;

    .inputField {
      border: none;
      padding: 0;
      padding-bottom: 1rem;
      font-size: 18px;
      font-family: $primary_font5;
      border-bottom: 1px solid $gray_MPB;
      border-radius: 0;
      &:focus {
        border-bottom: 1.5px solid black;
      }
    }
  }
}

.checkbox_wrapper {
  display: flex;
}
.checkboxLabel {
  font-family: $primary_font1;
  margin-left: 5px;
  letter-spacing: 0.2px;
  font-size: 12px;
  color: $gray2;
  height: 12px;
  line-height: 19px;
}

.forgetPasswordText {
  font-family: $primary_font5;
  color: $gray2;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    color: $darkest_gray;
    font-weight: 600;
    text-decoration: underline;
  }
}

.errorMessage {
  margin-top: 0.7rem;
  margin-left: 0.2rem;
  border-radius: 0.28571429rem;
  position: relative;
  color: $red1;
  width: 100%;
  text-align: center;
  padding: 3px 0;
  @include button;
  visibility: hidden;
}

.visible {
  visibility: visible;
}
