@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';
@import '../../theme/button';
@import '../../theme/responsive';

$cirleHeight: 100px;
$red: #ff1616;

:global(#app) {
  .customedSegment {
    border: 1px solid $gray_MBP_light;
    border-radius: 10px;
    padding: 2rem;
  }

  .nextButton {
    @include primary_btn;
  }
}

.container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.heroImage {
  margin-top: 3rem;
  width: 100%;
}

.welcome {
  margin-top: 2rem;
  padding-bottom: 4rem;
  @include h2Headline;
  text-align: center;

  @include Responsive(SM) {
    font-size: 22px;
  }

  span,
  b {
    @include h2Headline;
    font-weight: bold;
  }

  span {
    color: $red_MPB;
    font-weight: 400;

    &.mpbLogoColor {
      color: $gray_MPB;
    }
  }
}

.title {
  @include head3;
  text-align: center;
  font-weight: bold;

  @include Responsive(SM) {
    font-size: 24px;
  }
}

.stepContainer {
  display: flex;
  margin-top: 3rem;
  justify-content: center;
  font-family: $primary_font1;
  flex-wrap: wrap;

  img {
    @include Responsive(MD) {
      transform: scale(0.8);
    }

    @include Responsive(SM) {
      transform: scale(0.6);
    }
  }
}

.bottomBlock {
  text-align: right;
  margin-top: 1.5rem;
  margin-bottom: 5rem;

  @include Responsive(MD) {
    text-align: center;
  }
}
