@import '../../theme//palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';
@import '../../theme/button';

/*PROGRAM BLOCK*/
:global(#app) {
  .customedSegment {
    @include sharedMainContent_customedSegment;
    max-width: 1400px;
  }

  .button {
    @include primary_btn;
  }

  .disabledBtn {
    @include primary_disabled_btn;
  }

  .disabledSegment {
    font-style: italic;
  }
}

.programName {
  @include head2;
  font-size: 24px;
  color: $darkest_gray;
}

.table {
  width: 100%;
  thead {
    tr {
      th {
        padding: 1rem 0;
        @include overline;
        font-size: 16px;
        color: $gray1;
        &:not(:first-child) {
          font-weight: 700;
          text-align: center;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 0.5rem 0;
        @include overline;
        font-size: 16px;
        color: $gray1;
        text-transform: capitalize;
        &:not(:first-child) {
          font-weight: 700;
          font-size: 18px;
          text-align: center;
          color: $darkest_gray;
        }
      }
    }
  }
}

/* DISABLED - NOT READY STYLES */
.programNameNotReady {
  font-style: italic;
  margin-bottom: 0.2rem;
}

.wordNotReady {
  font-family: $primary_font1;
  color: $red_MPB;
  font-style: italic;
  font-weight: bold;
}
