@import '../../theme/palette';
@import '../../theme/text';

:global(#app) {
  .gridWrapper {
    height: calc(100vh - 118px);
    margin-bottom: 0;
  }

  .gridRow {
    padding-bottom: 0;
  }

  .rightBlock {
    border-left: 2px solid $gray_MBP_light;
    border-top: 2px solid $gray_MBP_light;
    padding-left: 0;
    padding-right: 0;
  }
}

.searchWrapper {
  border-bottom: 1px solid $gray_MBP_light;
  position: relative;
}

//CONTACT ITEM
.dividerBlock {
  position: relative;
  display: flex;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $gray_MPB;
    left: 0;
    top: calc(50% - 1.5px);
  }
}

.textBox {
  font-family: $primary_font1;
  font-size: 13px;
  color: $gray2;
  width: 15ch;
  text-align: center;
  background: $white1;
  z-index: 2;
}

.fixedDiaglogBlock {
  height: calc(100vh - 350px);
  overflow-x: hidden;
  overscroll-behavior: contain;
  padding-right: 10px;
}

// SEARCH RESULT

.searchResultWrapper {
  position: absolute;
  bottom: -34px;
  left: 2px;
  border: 2px solid $gray_MBP_light;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-color: $white1;
  min-width: 171px;
}

.searchResultItem {
  cursor: pointer;
  background-color: $white1;
  padding: 7px 6px;
  font-family: $primary_font1;
  font-weight: 400;

  &:hover {
    background-color: $gray2;
  }
}

.disabledItem {
  cursor: default;
  pointer-events: none;

  &:hover {
    background-color: $white1;
  }
}
