@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedForm';
@import '../../theme/sharedMainContent';
@import '../../theme/palette';

.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .customedGrid {
    height: 100%;

    .customedRow {
      justify-content: center !important;
      align-items: center !important;

      &.notHasUpcomingSession {
        align-items: flex-start !important;
        padding-top: 12rem;
      }

      .topWrapper {
        @include sharedMainContent_wrapper;
        background: rgba(243, 245, 249, 0.75);
        border-radius: 5px;
        backdrop-filter: blur(3px);
        flex: 0 1 auto;
        display: flex;
        margin-bottom: 30px;
        transition: all 0.5s;
        padding: 30px;
        min-height: 185px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
        border: 1px solid;
        border-top-color: $white1;
        border-left-color: $white1;
        border-right-color: $gray_MPB;
        border-bottom-color: $gray_MPB;

        @include Responsive(SM) {
          flex-direction: column;
          align-items: center;
          gap: 8px;
          padding: 15px;
        }

        h3 {
          @include sharedMainContent_title;
        }
        p {
          @include sharedMainContent_titleDesc;
        }

        .topWrapper__icon {
          margin-right: 0.5rem;

          img {
            width: 64px;
            height: 64px;
          }

          span {
            color: $dark_red_MPB;
            font-weight: 900;
            font-family: $primary_font4;
          }
        }

        .keyInformation {
          margin-left: 1.5rem;

          @include Responsive(SM) {
            margin-left: 0;
          }

          div {
            line-height: 1.7rem;
            color: $darkest_gray;
            font-weight: 500;
            font-size: 18px;

            span {
              color: $darkest_gray;
              font-weight: bolder;
            }
          }
        }
      }
    }
  }
}

.sessionTable {
  @include sessionTable;
  tbody {
    tr {
      &:nth-child(odd) {
        background-color: $white1;
      }
      &:nth-child(even) {
        background-color: $gray_MPB_lightest;
      }
    }
  }
}

.numberSessionTH {
  width: 40%;
}

.statusTH {
  width: 20%;
}

.coachTH {
  width: 40%;
}

.numberSessionTD {
  @include head2;
}

.statusTD {
  @include head2;
  color: $darkest_gray;
}

.coachTD {
  @include body1;
  font-size: 18px;
}

.noSessionTD {
  font-family: ManropeRegular;
  font-weight: 700;
  font-size: 18px;
  padding: 1rem 1rem;
}
