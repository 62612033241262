@import '../../theme/button';
@import '../../theme/responsive';

:global(#app) {
  .reportBtn {
    @include primary_btn;
    text-transform: uppercase;

    @include Responsive(SM) {
      margin-left: 5px;
      transform: scale(0.8);
    }
  }
}

.mobileLabel {
  display: none;
}

@media screen and (max-width: 680px) {
  .desktopLabel {
    display: none;
  }
  .mobileLabel {
    display: inline;
  }
}

@media screen and (max-width: 515px) {
  .mobileLabel {
    display: none;
  }
}
