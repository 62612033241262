@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';
@import '../../theme/responsive';

:global(#app) {
  .closeIcon {
    position: absolute;
    top: 5px;
    right: 0px;
    color: $white1;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .btnDisable {
    @include primary_disabled_btn;
    width: 100%;
    max-width: 15rem;
  }

  .btnActive {
    @include primary_btn;
  }

  .ratingBlock {
    @include Responsive(MD) {
      padding-top: 1rem;
      padding-bottom: 1rem;
      max-width: 300px;
      i {
        text-align: left;
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  opacity: 1;
  display: flex;
  justify-content: center;
}

.wrapper {
  text-align: center;
  width: 80%;
  max-width: 72rem;
  min-height: 10rem;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: absolute;
  top: 15%;
  background-color: $white1;
}

.title {
  color: $white1;
  @include head2;
  font-size: 1.7rem;
  background-color: $red1;
  padding: 1.1rem 0;
  border-radius: 8px 8px 0 0;
}

.contentWrapper {
  padding: 0 2rem 2rem 2rem;
  text-align: left;
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.generalQuestion {
  font-family: $primary_font1;
  font-weight: 700;
  line-height: 20px;
  color: $darkest_gray;
}

.text1 {
  font-family: $primary_font1;
  font-weight: 400;
  color: $darkest_gray;
}

.textArea {
  resize: none;
  border-radius: 10px;
  border: 1px solid $gray_MBP_light;
  padding: 1rem;
  height: 183px;
  font-family: $primary_font1;
  font-weight: 400;
  transition: all 0.5s;
  &:focus {
    outline: none;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
  }
  &::placeholder {
    font-family: $primary_font1;
    font-weight: 400;
  }
}

.buttonBlock {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @include Responsive(LG) {
    margin-top: 0.5rem;
    justify-content: flex-end;
  }
}
