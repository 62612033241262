@import '../../theme/palette';
@import '../../theme/text';

:global(#app) {
  .customedField {
    margin: 0 0 0.5em;
  }
  .customedDropDown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 3px 0;
    font-family: $primary_font1;
    border-bottom: 1px solid $gray_MBP_light;
  }
  .noBorder {
    border: none;
    padding-bottom: 0;
  }

  input[type='radio'],
  input[type='checkbox'] {
    accent-color: red;
  }
}

/*QUESTIONS*/
.questionWrapper {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.inputField {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.questionAnswer {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
}
.input {
  width: 20px;
  height: 20px;
  flex: 1;
}
.label {
  @include body1;
  margin-left: 1rem;
  line-height: 30px;
  flex: 20;
  color: $darkest_gray;
}

.questionTitle {
  @include StronglyEmphasize;
  font-size: 20px;
  margin: 0.5rem 0;
  line-height: 30px;
  color: $darkest_gray;
}

.dropDownBlock {
  display: flex;
  align-items: flex-end;
  margin-top: 2rem;
}

.dropDownItem {
  width: 12rem;
  cursor: pointer;
}

.flash {
  font-family: $primary_font1;
  font-size: 1.7rem;
  padding: 0 10px;
  font-weight: 700;
}
