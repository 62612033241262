@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedForm';
@import '../../theme/sharedMainContent';
@import '../../theme/responsive';

.wrapper {
  @include sharedMainContent_contentBlock;
}

.gridWrapper {
  opacity: 0.9;
}

.title {
  @include sharedMainContent_title;
}

:global(#app) {
  .gridWrapper {
    @include Responsive(SM) {
      margin-right: 0;
      margin-left: 0;
    }

    .wrapper {
      @include Responsive(SM) {
        padding: 0 0.5rem;
      }
    }
  }
}
