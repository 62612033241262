@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/responsive';

.wrapper {
  padding: 1rem 1.5rem;
}

.legendsBlock {
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding-top: 1rem;

  @include Responsive(SM) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 2rem;
  }
}

.legendTitle {
  font-family: $primary_font1;
  font-weight: 600;
  font-style: italic;
  margin-right: 1rem;

  @include Responsive(SM) {
    font-size: 18px;
    margin-bottom: 0.5rem;
  }
}

.legendItem {
  font-family: $primary_font1;
  font-weight: 600;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 50%;
    right: 110%;
    transform: translateY(-48%);

    @include Responsive(SM) {
      top: 25%;
      right: unset;
      transform: translateX(-186%);
    }
  }
  &:not(:last-child) {
    margin-right: 2rem;
  }

  @include Responsive(SM) {
    font-size: 18px;
    margin-left: 2rem;
    margin-top: 0.5rem;
  }
}

.normalLegend {
  &::before {
    background-color: $secondary_blue2;
  }
}

.anomalyLegend {
  &::before {
    background-color: $red1;
  }
}

.delayLegend {
  &::before {
    background-color: $other_yellow1;
  }
}

.searchAndPaginatorBlock {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 1rem;

  @include Responsive(SM) {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }
}

.searchBar {
  margin-right: 2rem;

  @include Responsive(SM) {
    margin-right: 0;
  }
}

.pagination {
  @include Responsive(SM) {
    margin-top: 1rem;
  }
}
