@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';
@import '../../theme/responsive';

:global(#app) {
  .sendBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $red1;
    border-radius: 10px;
    color: $white1;
  }

  .sendIcon {
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
    margin-right: 0;
    color: $white1;
  }
}

.wrapper {
  display: flex;

  @include Responsive(MD) {
    flex-wrap: wrap;
  }
}

.inputBlock {
  height: 100%;
  width: 100%;
  border: 1px solid $gray_MPB;
  border-radius: 10px;
}

.messageTitleBlock {
  width: 100%;
  padding: 1rem 1rem;
  border-bottom: 1px solid $gray_MPB;
}

.messageTitleInput {
  width: 100%;
  border: none;
  font-family: $primary_font1;
  font-weight: 700;
  &:focus {
    outline: none;
  }
}

.messageContentBlock {
  width: 100%;
  padding: 10px 1rem;
}

.content {
  word-break: break-word;
}

.textArea {
  width: 100%;
  height: 21px;
  border: none;
  resize: none;
  font-family: $primary_font1;
  &:focus {
    outline: none;
  }
}

.buttonBlock {
  padding-left: 0.5rem;
  display: flex;
  align-items: flex-end;

  @include Responsive(MD) {
    margin-top: 0.5rem;
  }
}
