@import './palette';
@import './text';

@mixin sharedMainContent_title {
  margin: 0;
  @include head3;
  color: $darkest_gray;
}

@mixin sharedMainContent_titleDesc {
  @include body3;
  line-height: 18px;
  margin-top: 5px;
  color: $gray1;
}

@mixin sharedMainContent_titleDescHighLight {
  @include sharedMainContent_titleDesc;
  font-weight: 600;
  color: $red1;
  opacity: 0.95;
}

@mixin sharedMainContent_wrapper {
  padding: 2rem 1rem;
  background-color: $gray_MPB_lightest;
}

@mixin sharedMainContent_titleAndNavBlock {
  padding: 0 1.2rem;
  border-bottom: 1px solid $gray_MBP_light;
}

@mixin sharedMainContent_contentBlock {
  padding: 0 1.2rem;
}

@mixin sharedMainContent_contentTitle {
  padding: 1rem 0;
  @include head1;
}

@mixin sharedMainContent_customedSegment {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 1px solid $gray_MBP_light;
}

/*COMPONENT ITEM IN DASHBOARD*/

@mixin shareMainContent_dashboard_item_title {
  margin-bottom: 0.5rem;
  @include head2;
  color: $darkest_gray;
}
