@import '../../theme/palette';
@import '../../theme/text';

:global(#app) {
  .customedSegment {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: 1px solid $gray_MBP_light;
    padding-left: 0;
    padding-right: 0;
  }

  .previewImage {
    width: auto;
    height: auto;
    max-height: 100px;
    max-width: 100px;
  }
}

.topBlock {
  border-bottom: 1px solid $gray_MBP_light;
  padding: 0 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 156px;
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomBlock {
  padding: 1rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-family: $primary_font1;
    font-weight: 400;
  }

  .photoName {
    font-weight: bold;
    margin-bottom: 5px;
  }
}
