@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';
@import '../../theme/responsive';

:global(#app) {
  .customedField {
    margin: 0 0 0.5em;
  }
  .btn {
    @include primary_btn;
    width: 10rem;
  }
  .btnBack {
    color: $darkest_gray;
    background-color: $white1;
    border: 1px solid $darkest_gray;
    &:hover {
      background-color: $white1;
      color: $darkest_gray;
    }
  }
  .progress {
    margin-top: 1.2rem;
    border-radius: 12px;
    background-color: $gray_MBP_light;
    height: 0.6rem;

    .bar {
      background-color: $red1 !important;
    }
  }
}

.wrapper {
  background-color: $white1;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 115px;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/diagnostic_bg_1442x680.png');
    background-repeat: no-repeat;
    background-size: cover;
    filter: opacity(80%);
    @include Responsive(XL) {
      background-position: center;
    }
    @include Responsive(LG) {
      background-image: none;
    }
    @include Responsive(MD) {
      background-image: none;
    }
  }
}

.titleWrapper {
  background-color: $red1;
  padding: 1rem 1rem;
  position: relative;
}
.categoryTitle {
  color: $white1;
  @include body1;
  font-size: 30px;
  text-align: center;
}

.btnBlock {
  width: 100%;
  display: flex;
  margin-top: 1.2rem;
  justify-content: space-between;
}

.successFormWrapper {
  margin-top: 18%;
}

.progressWrapper {
  padding: 1px 0;
  border-bottom: 2px solid $gray_MPB;
  position: relative;
  background-color: white;
}

$headerBar: 60px;
$questionTitleBlock: 66px;
$progressBarHeigh: 46.7px;

.formWrapper {
  min-height: calc(100vh - (#{$headerBar} + #{$questionTitleBlock} + #{$progressBarHeigh}));

  .formWrapperInner {
    padding: 1rem 1.2rem;
    filter: opacity(100%);
    background-color: $white1;

    @include Responsive(MD) {
      padding: 1rem 0;
    }
  }
}
