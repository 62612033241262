@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';
:global(#app) {
  .btnPlan {
    @include primary_btn;
    width: 100%;
    height: 3rem;
  }
}
