@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';
@import '../../theme/responsive';

.titleAndNavBlock {
  @include sharedMainContent_titleAndNavBlock;
}

.title {
  @include sharedMainContent_title;
  padding: 1.2rem 0 2rem 0;
}

.titleDescHighLight {
  @include sharedMainContent_titleDescHighLight;
}

.wrapper {
  @include sharedMainContent_wrapper;
}

.navBlock {
  display: flex;
  justify-content: flex-start;
}

:global(#app) {
  .navItem {
    @include overline;
    font-size: 18px;
    padding: 0.5rem 1.3rem;
    position: relative;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .active {
    &::after {
      content: '';
      height: 5px;
      width: 100%;
      background-color: $red1;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 8px;
    }
  }
}

.navItem_text {
  @include overline;
  font-size: 18px;
  color: $darkest_gray;

  @include Responsive(SM) {
    font-size: 16px;
  }
}

.downloadReport {
  position: absolute;
  right: 0;
  bottom: 2px;
}
