@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedForm';
@import '../../theme/sharedMainContent';


.wrapper{
    @include sharedMainContent_contentBlock;
}

.titleBlock{
    padding: 1rem 0;
}

.title{
    @include sharedMainContent_title;
}

.titleDesc{
    @include sharedMainContent_titleDesc;
}

