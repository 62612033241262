@import './palette';
@import './text';
@import './responsive';

#menu {
  .item {
    font-family: $primary_font1;
    font-size: 18px;
    color: $gray_MPB;

    @include Responsive(SM) {
      font-size: 15px;
    }
  }
  .active {
    border-bottom: 5px solid $red1;
    color: $darkest_gray !important;
  }
}
