@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';
@import '../../theme/sharedForm';
@import '../../theme/responsive';

:global(#app) {
  .objectiveButton {
    width: 188px;
    height: 59px;
    border: 1px solid $gray_MPB;
    background-color: $white1;
    border-radius: 0;
    margin: 0 0 0.5rem 0;
    span {
      color: $darkest_gray;
      @include head2;
    }
  }

  .objectiveBtnActive {
    border: 1px solid $darkest_gray;
    background-color: $darkest_gray;
    span {
      color: $white1;
    }
  }

  .plusIcon {
    color: $darkest_gray;
    font-size: 18px;
  }

  .closeIcon {
    font-size: 1.7rem;
    color: $white1;
    position: absolute;
    right: 0;
    top: 5px;
    cursor: pointer;
  }

  .saveBtn {
    @include primary_disabled_btn;
    height: 3.5rem;
  }

  .activeSaveBtn {
    @include tertiary_btn;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  opacity: 1;
  display: flex;
  justify-content: center;
}

.wrapper {
  text-align: center;
  width: 80%;
  max-width: 100rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border: 1px solid $gray_MPB;
  position: absolute;
  top: 10%;
  background-color: $white1;
}

.header {
  @include h2Headline;
  font-weight: 700;
  color: $white1;
  text-align: center;
  padding: 25px 0;
  background-color: $red1;
  letter-spacing: 1px;
  position: relative;

  @include Responsive(MD) {
    font-size: 18px;
  }

  @include Responsive(SM) {
    font-size: 16px;
  }
}

.bottomBlock {
  padding: 1.2rem 1.2rem 1.5rem 1.2rem;
  max-height: 80vh;
  overflow-y: auto;
}

.leftBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include Responsive(LG) {
    display: block;
    text-align: left;
  }
}

/*RIGHT block*/

.rightBlock {
  text-align: left;
  border: 1px solid #a7a9ac;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  padding: 1.5rem 1.2rem 1.5rem 1.2rem;
}

.textAreaTitle {
  display: flex;
  justify-content: space-between;
  margin: 0 0 1rem 0;
  padding: 0 1rem;
  span {
    @include head2;
    &:first-child {
      color: $gray2;
    }
    &:nth-child(2) {
      color: $red_MPB;
      cursor: pointer;
    }
  }
}

.textArea {
  width: 100%;
  border-radius: 10px;
  border: 1px solid $gray_MBP_light;
  padding: 1rem;
  transition: all 0.4s;
  margin-bottom: 1rem;
  resize: none;
  &:focus {
    outline: none;
  }
}

.editEffect {
  &:focus {
    outline: none;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.25);
  }
}

.objectiveArea {
  height: 15rem;
}

.indicatorArea {
  height: 12rem;
}

.buttonBlock {
  padding: 1.2rem 0 0 0;
  text-align: right;
}

.textWarningSetObjective {
  font-family: $primary_font1;
  color: $red_MPB;
  font-size: 16px;
  margin-bottom: 0;
  margin: 0 0.25em 0.15em 0;
  font-style: italic;
}

.sup {
  @include sharedForm_sup;
}
