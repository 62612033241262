@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';
@import '../../theme/responsive';
.wrapper {
  padding: 0rem 1.5rem 2rem 1.5rem;
}
.title {
  @include sharedMainContent_title;
  padding: 1.2rem 0 2rem 0;
}

.toolsBlock {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @include Responsive(SM) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.searchWrapper {
  margin-right: 1rem;

  @include Responsive(SM) {
    margin-bottom: 1rem;
    margin-right: 0;
  }
}

/*RESOURCE BLOCK*/

.categoryBlock {
  padding: 1rem 0;
}

.categoryTitle {
  font-family: $primary_font1;
  font-size: 18px;
  font-weight: 700;
}

/*RESOURCE ITEM*/

:global(#app) {
  .customSegment {
    border: 1px solid $gray_MBP_light;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    align-items: center;
    // justify-content: center;
    margin-top: 1rem;
  }
  .resourceIcon {
    font-size: 1.7rem;
    display: flex;
    align-items: center;
    color: $red1 !important;
  }
  .customedCoachMediaResourceGrid {
    margin-bottom: 0;
  }
  .resourceItem {
    &:hover {
      border-color: $red1;
      box-shadow: 0px 1px 3px $red1;
    }
  }
}

.resourceTitle {
  font-family: $primary_font1;
  font-weight: 700;
  color: $darkest_gray;
  padding-left: 5px;
}

.mediaItemContent {
  display: flex;
  align-items: center;
}
