@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';
@import '../../theme/responsive';

:global(#app) {
  .closeIcon {
    position: absolute;
    top: 5px;
    right: 0px;
    color: $white1;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .infoIcon {
    width: 2rem;
    height: 2rem;
    border: 2px solid $darkest_gray;
    // color: $grey7;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  opacity: 1;
  display: flex;
  justify-content: center;
}

.wrapper {
  text-align: center;
  width: 80%;
  max-width: 72rem;
  min-height: 22rem;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: absolute;
  top: 5%;
  background-color: $white1;
  .contentWrapper {
    padding: 1.5rem 1.5rem;
    text-align: left;
  }
}

.title {
  color: $white1;
  @include head2;
  font-size: 1.7rem;
  padding: 1.1rem 0;
  background-color: $red1;
  border-radius: 8px 8px 0 0;

  @include Responsive(SM) {
    font-size: 1.2rem;
  }
}

.titleBgDanger {
  background-color: $red_MPB;
}

.content {
  font-family: $primary_font1;
  font-size: 24px;
  font-weight: 700;

  @include Responsive(SM) {
    font-size: 20px;
  }
}

.textHiglightInfo {
  color: $red1;
  font-size: inherit;
}

/*BUTTON BLOCK*/

.btnBlock {
  text-align: right;
  padding: 1.2rem 1.5rem 0rem 1.5rem;
}

:global(#app) {
  .cancelBtn {
    @include secondary_btn;
    margin-right: 2rem;
    width: 12rem;

    @include Responsive(SM) {
      display: block;
      width: 100%;
    }
  }

  .agreeBtn {
    @include primary_disabled_btn;
    width: 12rem;

    @include Responsive(SM) {
      display: block;
      width: 100%;
      margin-top: 0.5rem;
    }
  }

  .styleInfo {
    @include primary_btn;
  }

  .styleDanger {
    @include tertiary_btn;
  }
}

/*REMOVE COACHEE CONTENT*/

.contentWarning {
  font-family: $primary_font1;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 2rem;
}

/*ASSIGN CONTENT*/

.courseItemsBlock {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
}

:global(#app) {
  .courseItem {
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 277px;
    background-color: $gray_MBP_light;
    border: 1px solid $gray_MBP_light;
    box-shadow: none;
    pointer-events: none;
    margin-top: 0.5rem;

    @include Responsive(XL) {
      min-width: 245px;
    }

    @include Responsive(LG) {
      min-width: 230px;
    }
  }

  .activeCourseItem {
    border: 1px solid $darkest_gray;
    background-color: $white1;
    cursor: pointer;
    transition: all 0.4s;
    pointer-events: auto;
  }

  .selectedCourse {
    border: 1px solid $red1;
    background-color: $red1;
    p {
      color: $white1;
      span {
        color: inherit;
      }
    }
  }
}

.offerName {
  font-family: $primary_font1;
  font-weight: 700;
  margin-bottom: 0;
  p {
    color: $white1;
  }
}

.offerDesc {
  font-family: $primary_font1;
  font-weight: 400;
  font-style: italic;
  color: $gray_MPB;
}

.offerDescInfo {
  color: $darkest_gray;
}

/*SUCCESS BLOCK*/
:global(#app) {
  .iconCheck {
    color: $green1;
  }
}

.successBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.successText {
  margin-top: 1rem;
  font-family: $primary_font1;
  font-weight: 700;
}

.successMessage {
  margin-top: 1rem;
  font-family: $primary_font1;
  font-weight: 400;
  font-size: 14px;
}

/*UNASSIGN CONTENT*/

.courseCheckboxItem {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.disabledCheckbox {
  span {
    color: $gray_MPB;
  }
}

:global(#app) {
  .checkbox {
    margin-right: 1rem;
  }
}

.unAssignWrapper {
  text-align: left;
  padding: 2rem 0 5rem 0;
}

.unAssignMultiWrapper {
  text-align: left;
}

.courseCheckboxName {
  font-family: $primary_font1;
  font-weight: 700;
  font-size: 20px;
  padding-right: 5px;
}

.courseAdditionalText {
  font-family: $primary_font1;
  font-weight: 500;
  font-size: 20px;
  padding-right: 5px;
}

.courseCheckboxDate {
  font-family: $primary_font1;
  font-weight: 400;
  font-size: 20px;
}
