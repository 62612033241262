@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/responsive';
@import '../../theme/sharedForm';
@import '../../theme/button';
@import '../../theme/sharedMainContent';
:global(#app) {
  .customedSegment {
    @include sharedMainContent_customedSegment;
  }

  .noBorderSegment {
    box-shadow: none;
    border: none;
  }
  .uploadIcon {
    font-size: 4rem;
    color: $gray_MPB;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  .btn {
    height: 3.5rem;
    width: 13rem;
  }
  .btnLeft {
    margin-right: 2.5rem;
    @include Responsive(SM) {
      margin-right: 0;
    }
  }
  .btnUpload {
    @include primary_disabled_btn;

    @include Responsive(SM) {
      display: block;
      width: 100%;
    }
  }
  .btnCancel {
    @include primary_disabled_btn;
    @include Responsive(SM) {
      display: block;
      width: 100%;
      margin-right: 0;
      margin-top: 0.75rem;
    }
  }
  .uploadActive {
    @include primary_btn;
  }
}
.title {
  @include head2;
  color: $darkest_gray;
}

.titleDesc {
  @include subtitle;
  color: $gray1;
}

.uploadWrapper {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10.14rem;
  border: 3px dashed $red1;

  @include Responsive(SM) {
    height: fit-content;
    padding: 8px;
  }

  .uploadResultDesc {
    @include head2;
    font-size: 24px;
    color: $darkest_gray;
    text-align: center;
    margin: 8px 0;
  }

  .uploadDesc {
    @include head2;
    font-size: 16px;
    color: $gray1;
    text-align: center;
  }

  .textClickable {
    color: $red1;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    &::after {
      content: '';
      height: 1.5px;
      width: 100%;
      background-color: $red1;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .uploadFileName {
    @include head2;
    font-size: 24px;
    color: $darkest_gray;
    text-align: center;
    word-break: break-all;
  }
  .uploadFileDesc {
    @include body1;
    font-size: 24px;
    color: $darkest_gray;
  }
}

.btnGroup {
  text-align: right;
  margin-top: 2.4rem;

  @include Responsive(SM) {
    text-align: center;
  }
}

.layer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $gray_MBP_light;
  opacity: 0.4;
}
