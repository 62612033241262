@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/responsive';

:global(#app) {
  .grid {
    margin-bottom: 0;
  }
}

.wrapper {
  padding: 0rem 0 0 2rem;

  @include Responsive(LG) {
    padding: 0rem 1.5rem 0 2rem;
  }
}

.monthTitle {
  font-family: $secondary_font1;
  font-size: 30px;
  color: $red1;
  text-align: center;
  font-weight: 700;
  padding-top: 1rem;
}
