@import '../../theme/palette';
@import '../../theme/text';

:global(#app) {
  .wrapper {
    flex: 1 1;
    background-color: $white1;
  }

  .rightColumnWrapper {
    padding-left: 0;
    padding-right: 0;
    border-top: 1px solid $red1;
    border-left: 1px solid $red1;
  }

  .m0 {
    margin: 0;
  }
  .p0 {
    padding: 0;
  }
  .leftColumn {
    padding: 0;
  }
}

.leftBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.navLink {
  @include body1;
  color: $darkest_gray;
  padding: 10px 5px 10px 8px;
  border: none;
  border-bottom: 1px solid $red1;
  &:hover {
    color: $darkest_gray;
  }
}

.activeLink {
  background-color: $red1;
  color: white;
  &:hover {
    color: $white1;
  }
}

.myNavLink {
  cursor: pointer;
}
