@import '../../theme/palette';
@import '../../theme/text';

:global(#app) {
  .smileIcon {
    color: $gray_MPB;
  }
}

.welcomeMessage {
  text-align: center;
  padding-top: 1rem;
}

.sentence1 {
  margin-bottom: 5px;
}

.sentence1,
.sentence2 {
  font-family: $primary_font1;
  font-size: 14px;
  color: $gray_MPB;
}

.nameText {
  font-family: inherit;
  font-size: inherit;
  color: $darkest_gray;
}
