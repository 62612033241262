@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/responsive';

:global(#app) {
  .customSegment {
    padding-left: 0;
    padding-right: 0;
    border: 2px solid $gray_MBP_light;
    border-radius: 10px;
  }

  .violetBorder {
    border-color: $violet;
  }
}

// TOP BLOCK
.topBlock {
  padding: 0 1rem 1rem 1rem;
  border-bottom: 2px solid $gray_MBP_light;
}

.topLeft {
  @include Responsive(MD) {
    display: flex;
    justify-content: space-between;
  }
}

.topRight {
  display: flex;
  justify-content: space-between;

  @include Responsive(MD) {
    margin-top: 1rem;
  }
}

.text1 {
  font-family: $primary_font1;
  font-weight: 700;
  font-size: 18px;
  color: $gray_MPB;
  margin-right: 0.9rem;

  @include Responsive(MD) {
    margin-right: 0;
  }
}

.text3 {
  font-family: $primary_font1;
  font-weight: 600;
  font-size: 18px;
  color: $gray_MPB;
}

.text4 {
  font-family: $primary_font1;
  font-weight: 600;
  font-size: 18px;
  color: $darkest_gray;
}

.textDanger {
  font-family: $primary_font1;
  font-weight: 700;
  font-size: 18px;
  color: $red1;
}

//BOTOM BLOCK
.bottomBlock {
  padding: 1rem 1rem 0 1rem;
}

.bottomRight {
  display: flex;
  flex-direction: column;

  @include Responsive(MD) {
    margin-top: 1rem;
  }
}

.text5 {
  font-family: $primary_font1;
  font-weight: 700;
  font-size: 18px;
}

.text6 {
  font-family: $primary_font1;
  font-weight: 400;
  font-size: 18px;
  color: $gray_MPB;
  margin-bottom: 0.5rem;
}

.bold700 {
  font-weight: 700;
}

.text1,
.text3,
.text4 {
  @include Responsive(MD) {
    font-size: 16px;
  }
}
