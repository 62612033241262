@import '../../theme/palette';
@import '../../theme/text';

:global(#app) {
  .searchIcon {
    color: $gray_MBP_light;
    margin-right: 1em;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.input {
  width: 100%;
  padding: 10px 5px;
  border: none;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-family: $primary_font1;
    font-size: 14px;
    font-weight: 400;
    color: $gray_MPB;
  }
}

.submitBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    border: none;
    outline: none;
  }
}
