@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';
@import '../../theme/responsive';

.wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.numberBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 100%;
}

.numberContainer {
  font-family: $secondary_font1;
  line-height: initial;
  margin: 0;
  font-size: 48px;
}

.primaryNumber {
  font-size: 48px;
  font-weight: 700;
  color: $red1;
}

.secondaryNumber {
  font-size: 64px;
}

.label {
  text-align: center;
  @include overline;
  font-size: 18px;
  line-height: 1.3rem;
  color: $darkest_gray;
  @include Responsive(SM) {
    font-size: 15px;
  }
}
