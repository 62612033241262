@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';

:global(#app) {
  .customedField {
    margin: 0 0 0.5em;
  }
  .btn {
    @include primary_btn;
    width: 10rem;
    transition: all 0.4s;
  }
  .btnBack {
    // color: $darkest_gray;
    // background-color: $white1;
    // border: 1px solid $darkest_gray;
    // &:hover {
    //   background-color: $white1;
    //   color: $darkest_gray;
    // }
    @include secondary_btn;
  }
  .invisible {
    opacity: 0;
  }
  .nextBtnInvisible {
    opacity: 0;
    pointer-events: none;
  }
}

.btnBlock {
  width: 100%;
  display: flex;
  margin-top: 4rem;
  justify-content: space-between;
}
