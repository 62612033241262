@import '../../theme/palette';

:global(#app) {
  .customedCheckbox {
    input {
      &:focus:checked ~ label {
        &::before {
          background-color: $red1 !important;
        }
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  label {
    padding-left: 1rem;
    font-family: ManropeRegular;
    font-weight: 400;
  }
}
