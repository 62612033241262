@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';
@import '../../theme/responsive';

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  padding-bottom: 1rem;
}

.topWrapper {
  @include sharedMainContent_wrapper;
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  h3 {
    @include sharedMainContent_title;
  }
  p {
    @include sharedMainContent_titleDesc;
  }

  .topWrapper__icon {
    margin-left: 2.5rem;
    margin-right: 0.5rem;

    img {
      width: 64px;
      height: 64px;
    }

    span {
      color: $dark_red_MPB;
      font-weight: 900;
      font-family: $primary_font4;
    }
  }

  .keyInformation {
    margin-left: 1.5rem;

    div {
      line-height: 1.7rem;
      color: $darkest_gray;
      font-weight: 500;
      font-size: 18px;

      span {
        color: $darkest_gray;
        font-weight: bolder;
      }
    }
  }
}

:global(#app) {
  .customedGrid {
    flex: 1 1 auto;
    margin-left: 0;
    margin-right: 0;
  }
  .customedGridLeftColumn {
    padding-right: 0;
    padding-left: 0;
  }
  .customedGridRighColumn {
    padding-left: 0;
  }
  .customedGridRow {
    padding-bottom: 0;
  }
}

.leftBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid $red1;
  border-top: 1px solid $red1;

  @include Responsive(LG) {
    border-right: unset;
  }
}

.navLink {
  @include body1;
  color: $darkest_gray;
  padding: 10px 5px 10px 8px;
  border-bottom: 1px solid $red1;
  &:hover {
    color: $darkest_gray;
  }
}
.activeLink {
  background-color: $red1;
  color: white;
  &:hover {
    color: $white1;
  }
}
.disabledNav {
  pointer-events: none;
  background-color: $gray_MPB_lightest;
  color: $gray_MPB;
  opacity: 0.5;
}

.rightBlockContainer {
  border-top: 1px solid $red1;
  position: relative;

  &.sessionBackground {
    background-image: url('../../assets/images/bg_sessions.webp');
    background-size: cover;

    @include Responsive(SM) {
      background-image: unset;
    }
  }
}

.rightBlock {
  padding: 1rem 1.2rem 2rem 1.2rem;
  overflow-x: auto;
}
