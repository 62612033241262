@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';
@import '../../theme/responsive';

:global(#app) {
  .customedGridRow {
    @include Responsive(SM) {
      padding-left: 1rem;
      padding-right: 1rem;
      gap: 0.9rem;
    }
  }

  .customedGridColumn {
    @include Responsive(SM) {
      padding: 0;
    }
  }

  .customedSegment {
    @include sharedMainContent_customedSegment;
    box-shadow: none;
    padding: 1rem 1em;
  }

  .firstChartItem {
    @include Responsive(MD) {
      margin-bottom: 1rem;
    }
  }
}

.title {
  @include sharedMainContent_title;
  padding-bottom: 1.5rem;
  padding-top: 1rem;
}
