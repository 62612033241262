@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';

.wrapper {
  height: 100%;
  background: $white1;
  display: flex;
  flex-direction: column;
}

.title {
  @include sharedMainContent_title;
  padding: 0 1rem 1.2rem 1rem;
}

.mainContentWrapper {
  flex: 1 1 auto;
  padding-left: 1rem;
}
