@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';

:global(#app) {
  .closeIcon {
    position: absolute;
    top: 9px;
    right: 0px;
    color: $white1;
    font-size: 2rem;
    cursor: pointer;
  }
  .btn {
    width: 12rem;
    outline: none;
    cursor: pointer;
    padding: 15px 0;
    border: none;
  }
  .cancelBtn {
    @include overline;
    @include secondary_btn;
  }
  .agreeBtn {
    @include primary_disabled_btn;
    margin-left: 1rem;
  }
  .activeSaveBtn {
    @include primary_btn;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  opacity: 1;
  display: flex;
  justify-content: center;
}

.wrapper {
  text-align: center;
  width: 80%;
  max-width: 50rem;
  min-height: 22rem;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: absolute;
  top: 20%;
  background-color: $white1;
  .title {
    color: $white1;
    @include head2;
    font-size: 1.7rem;
    background-color: $red1;
    padding: 1.1rem 0;
    border-radius: 8px 8px 0 0;
  }
  .contentWrapper {
    padding: 0 1.5rem;
    text-align: left;
    margin-top: 4rem;
  }
  .content {
    @include head1;
  }
}

.buttonsWrapper {
  display: flex;
  padding: 4rem 1.5rem 1rem 1.5rem;
  justify-content: flex-end;
}
