/*OLD PALETTE*/

$secondary_blue2: #4292ff;
$secondary_blue3: #bbe7ff;
$silverTree: #61b885;
$other_yellow1: #fdc002;
$other_secondary_yellow: #ffc700;
$violet: #9100ff;
$green1: #2da850;
$yellow1: #ffc000;

// NEW PALETTE

/*RED*/
$red_MPB: #ed1e24;
$red1: #eb5757;
$dark_red_MPB: #ca1319;

/*GRAY*/
$gray_MPB: #a7a9ac;
$gray_MBP_light: #d7d9dc;
$gray1: #515151;
$gray2: #414141;
$darkest_gray: #222222;
$gray_MPB_lightest: #f3f5f9;

/*WHITe*/
$white1: #ffffff;
