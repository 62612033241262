@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';
@import '../../theme/responsive';

:global(#app) {
  .sessionBlock {
    border-radius: 10px;
    overflow: hidden;
    padding: unset;
    border: 1px solid $gray_MBP_light;
    margin-bottom: 15px;
  }

  .redBorder {
    border-color: $red1;
  }

  .violetBorder {
    border-color: $violet;
  }

  .cancelSessionBtn {
    @include tertiary_btn;
    height: 3rem;
    margin-top: 1.2rem;
    background-color: $white1;
  }
  .joinBtn {
    @include primary_btn;
    width: 100%;
    height: 3rem;
  }
  .disableBtn {
    @include primary_disabled_btn;
  }

  .editVideoLinkBlock {
    display: flex;
    cursor: pointer;
    align-items: center;
    color: $gray2;
    .videoIcon {
      color: $gray_MPB_lightest;
    }
    &:hover {
      .videoIcon {
        color: $darkest_gray;
      }
      .text7 {
        color: $darkest_gray;
        text-decoration: underline;
      }
    }
  }

  .needSetVideoLink {
    .videoIcon,
    .text7 {
      color: $darkest_gray;
    }
    .text7Danger {
      color: $red_MPB;
    }
    .videoIconDanger {
      color: $red_MPB;
    }
  }
}

.bottomMid {
  @include Responsive(MD) {
    margin-top: 10px;
  }
}

.topBlock {
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $red1;

  @include Responsive(MD) {
    flex-direction: column;
    text-align: center;
  }

  .text2Wrapper {
    @include Responsive(MD) {
      margin-top: 10px;
    }
  }

  span {
    @include StronglyEmphasize;
    font-size: 24px;
    letter-spacing: 1px;
  }
}

.borderBottom {
  color: $white1;
}

.bottomBlock {
  padding: 1.5rem 2rem;
  background-color: $white1;
}

.text2 {
  @include overline;
  font-size: 24px;
  color: $white1;

  @include Responsive(SM) {
    font-size: 20px;
  }

  &:last-child {
    padding-left: 1rem;
  }
  span {
    @include overline;
    font-size: 24px;
    color: $darkest_gray;
    padding-left: 0.5rem;

    @include Responsive(SM) {
      font-size: 20px;
    }
  }
}

.sessionStatusExplaination {
  @include h2Headline;
  font-weight: 600;
  color: $darkest_gray;
  margin-bottom: 0.5rem;
}

.coachName {
  @include StronglyEmphasize;
  font-size: 18px;
  color: $gray_MPB;
}

.rightBtn {
  display: flex;
  flex-direction: column;

  @include Responsive(MD) {
    margin-top: 1.5rem;
  }
}
