@import '../../theme/palette';
@import '../../theme/text';
.wrapper {
  margin-top: 1.2rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  thead {
    th {
      background-color: $red1;
      padding: 1rem 1rem;
      text-align: center;
      @include head2;
      color: $white1;
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background-color: $white1;
      }
      &:nth-child(even) {
        background-color: $gray_MPB_lightest;
      }
    }
    td {
      text-align: center;
      @include head2;
      padding: 1rem 1rem;
    }
  }
}

.thNumberSession {
  border-radius: 8px 0 0 0;
  width: 20%;
}
.thDate {
  width: 25%;
}

.thStatus {
  width: 10%;
}

.thCoach {
  width: 25%;
}
.thNote {
  width: 20%;
  border-radius: 0 8px 0 0;
}

.tdNumberSession {
  @include head2;
  font-weight: bold;
  letter-spacing: 1px;
  font-weight: bold;
}

.tdDate,
.tdCoach {
  @include head1;
}
.tdNote {
  @include head2;
  color: $red1;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.noSessionTD {
  text-align: center;
}
