@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';

.contentBlock {
  padding: 5% 10% 0 10%;
}

.title {
  @include StronglyEmphasize;
  font-weight: 400;
  font-size: 46px;
  color: $secondary_blue2;
}

.textContent {
  @include h2Headline;
}

.email {
  @include h2Headline;
  color: $secondary_blue2;
  border-bottom: 2px solid $secondary_blue2;
  &:hover {
    color: $secondary_blue2;
  }
}

:global(#app) {
  .btn {
    border-radius: 8px;
    width: 14rem;
    &:not(:first-child) {
      @include primary_btn;
      margin-left: 4.5rem;
    }
  }
}

.btnBlock {
  margin-top: 8.5rem;
}

.btnTitle {
  @include h2Headline;
  padding-left: 1rem;
}
