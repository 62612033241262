@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedMainContent';
@import '../../theme/responsive';

:global(#app) {
  .customedSegment {
    @include sharedMainContent_customedSegment;
  }

  .sortIcon {
    cursor: pointer;
    position: absolute;
    right: 0;
  }
}

.wrapper {
  padding: 2rem 2rem 2rem 2rem;
  background-color: $gray_MPB_lightest;
}

.title {
  @include sharedMainContent_title;
}

.titleDesc {
  color: $gray1;
}

.secondBlock {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.rightBlock {
  display: flex;
  align-items: center;

  @include Responsive(SM) {
    flex-direction: column;
    width: 100%;
  }
}

.pagination {
  padding-left: 1rem;

  @include Responsive(SM) {
    margin-top: 1rem;
    padding-left: 0;
  }
}

.listWrapper {
  padding: 1rem 0;
}
/*TABLE*/
.tableWrapper {
  // height: 60vh;
  overflow-y: auto;
  margin-top: 0.8rem;
  box-shadow: 2px 0px 9px rgba(40, 40, 40, 0.25);
  border-radius: 8px;
  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border-radius: 8px 8px 0 0;

    thead {
      tr {
        height: 3.5rem;
        th {
          position: relative;
          top: 0;
          @include head2;
          font-size: 14px;
          background-color: $red1;
          color: $white1;
          z-index: 1;
          padding: 8px 16px;
          &:first-child {
            border-radius: 8px 0 0 0;
          }
          &:last-child {
            border-radius: 0 8px 0 0;
          }
          .headerTitleBlock {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          span {
            @include head2;
            color: $white1;
            text-align: center;
          }
        }
      }
    }
  }
}

.selectLength {
  width: 5%;
}
.nomLength {
  width: 20%;
}
.departmentLength {
  width: 15%;
}
.courseLength {
  width: 20%;
}
.startedCourseLength {
  width: 10%;
}
.sessionLength {
  width: 10%;
}
.statusLength {
  width: 10%;
}
.createDateLength {
  width: 10%;
}

.invisible {
  opacity: 0;
  transition: all 0.5s;
}
