@import '../../theme/palette';
@import '../../theme/text';

:global(#app) {
  .noteBlock {
    border: 2px solid $gray_MBP_light;
    border-radius: 10px;
    padding: 0;
  }

  .icon1 {
    font-size: 2rem;
  }

  .closeIcon {
    color: $red_MPB;
    cursor: pointer;
  }
}

.wrapper {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noteWrapper {
  width: 100%;
  max-width: 71rem;
}

.topNoteBlock {
  padding: 1.2rem 1.5rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid $gray_MBP_light;
  align-items: center;

  span {
    @include overline;
    color: $gray_MPB;
    font-size: 14px;
  }
}

.bottomNoteBlock {
  padding: 1.2rem 1.5rem;
  height: 30rem;
}

.noteText {
  @include body1;
  font-size: 14px;
}
