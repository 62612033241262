@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/button';

:global(#app) {
  .joinBtn {
    @include primary_btn;
    width: 100%;
    height: 3rem;
  }
  .disableBtn {
    @include primary_disabled_btn;
  }
}
