@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/responsive';
@import '../../theme/button';

:global(#app) {
  .submitButton {
    @include primary_btn;
    width: 100%;
    margin: 3.8rem 0 0 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 24px;

    .formBlock {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  input:-webkit-autofill {
    border-color: $red1 !important;
  }

  .formField {
    width: 100%;
    margin-top: 10%;

    .inputField {
      border: none;
      padding: 0;
      padding-bottom: 1rem;
      font-size: 18px;
      font-family: $primary_font5;
      border-bottom: 1px solid $gray_MPB;
      border-radius: 0;
      &:focus {
        border-bottom: 1.5px solid black;
      }
    }
  }
}

.title {
  @include body1;
  font-weight: 700;
  margin: 3px 0 !important;
  letter-spacing: 0.75px;
}
.description {
  @include body2;
  margin: 3px 0 !important;
  text-align: center;
}

.forgetPasswordText {
  font-family: $primary_font5;
  color: $gray2;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    color: $darkest_gray;
    text-decoration: underline;
    font-weight: 600;
  }
}

.errorMessage {
  margin-top: 0.7rem;
  margin-left: 0.2rem;
  border-radius: 0.28571429rem;
  position: relative;
  color: #ff0000;
  width: 100%;
  text-align: center;
  padding: 3px 0;
  @include button;
}
