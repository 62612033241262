@import '../../theme/palette';
@import '../../theme/text';
@import '../../theme/sharedForm';
@import '../../theme/sharedMainContent';

.wrapper {
  @include sharedMainContent_contentBlock;
}

.gridWrapper {
  background-color: $gray_MBP_light;
  opacity: 0.9;
}

.title {
  @include sharedMainContent_title;
}
