@import "./text";

#customedDropDownID {
  .text {
    font-family: $primary_font1;
  }
  .divider {
    font-weight: 700;
  }
}
